import React, { forwardRef, memo, Ref, SVGProps } from 'react';

const DeviceIcon = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => (
  <svg
    width="100%"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <rect
      x="3.73914"
      y="3.73914"
      width="13.5217"
      height="13.5217"
      rx="2"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21739 2.73913H8.21739V0H7.21739V2.73913ZM9.95652 2.73913H10.9565V0H9.95652V2.73913ZM12.6957 2.73913H13.6957V0H12.6957V2.73913ZM15.4348 2.74409C15.7879 2.76427 16.1247 2.84555 16.4348 2.97751V0H15.4348V2.74409ZM17.9838 4.47826C18.1269 4.78678 18.2191 5.12371 18.2497 5.47826H21V4.47826H17.9838ZM18.2609 7.21739V8.21739H21V7.21739H18.2609ZM18.2609 9.95652V10.9565H21V9.95652H18.2609ZM18.2609 12.6957V13.6957H21V12.6957H18.2609ZM18.2559 15.4348C18.2357 15.7879 18.1545 16.1247 18.0225 16.4348H21V15.4348H18.2559ZM16.4348 18.0225C16.1247 18.1545 15.7879 18.2357 15.4348 18.2559V21H16.4348V18.0225ZM13.6957 18.2609H12.6957V21H13.6957V18.2609ZM10.9565 18.2609H9.95652V21H10.9565V18.2609ZM8.21739 18.2609H7.21739V21H8.21739V18.2609ZM5.47826 18.2497C5.12371 18.2191 4.78678 18.1269 4.47826 17.9838V21H5.47826V18.2497ZM2.97751 16.4348C2.84555 16.1247 2.76427 15.7879 2.74409 15.4348H0V16.4348H2.97751ZM2.73913 13.6957V12.6957H0V13.6957H2.73913ZM2.73913 10.9565V9.95652H0V10.9565H2.73913ZM2.73913 8.21739V7.21739H0V8.21739H2.73913ZM2.75032 5.47826C2.78086 5.12371 2.87307 4.78678 3.01617 4.47826H0V5.47826H2.75032ZM5.47826 0V2.75032C5.12371 2.78086 4.78678 2.87307 4.47826 3.01617V0H5.47826Z"
      fill="currentColor"
    />
  </svg>
);

const Composed = memo(forwardRef(DeviceIcon));
Composed.displayName = 'DeviceIcon';

export default Composed;
