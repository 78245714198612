import base64url from 'base64url';
import { useParams } from 'react-router-dom';

type StringOrUndefined = { [key: string]: string | undefined };

const useEncodedParams = <T extends StringOrUndefined>(): T => {
  const params = useParams<T>();
  const decode = { ...params };
  Object.keys(params).forEach((key) => {
    (decode as Record<string, string>)[key] = base64url.decode(params[key] as string);
  });
  return decode;
};

export default useEncodedParams;
