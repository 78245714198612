import React, { forwardRef, memo, Ref, SVGProps } from 'react';

const NoDataIcon = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => (
  <svg
    width="82"
    height="101"
    viewBox="0 0 82 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <path
      d="M81.205 14.904C81.1867 14.881 81.1645 14.8566 81.1378 14.8299L67.1694 0.862193C67.1327 0.825537 67.0976 0.794226 67.064 0.766733C66.5241 0.276452 65.8215 0 65.0883 0H12.9993C11.3742 0 10.0561 1.31811 10.0561 2.94322V62.7423C4.05132 66.1987 0 72.6816 0 80.0931C0 91.1229 8.97399 100.097 20.0038 100.097C27.1014 100.097 33.3468 96.3808 36.8987 90.793H79.056C80.6819 90.793 82 89.4749 82 87.8498V16.9117C82 16.164 81.7136 15.4485 81.205 14.904V14.904ZM71.9278 13.9463L68.0323 13.9341V10.05L68.7937 10.8114L71.9278 13.9463ZM20.0038 94.2097C12.2204 94.2097 5.8872 87.8773 5.8872 80.0931C5.8872 72.3089 12.2204 65.9765 20.0038 65.9765C27.788 65.9765 34.1212 72.3089 34.1212 80.0931C34.1212 87.8773 27.7887 94.2097 20.0038 94.2097ZM39.4211 84.9058C39.8037 83.3639 40.0084 81.7518 40.0084 80.0931C40.0084 69.0625 31.0344 60.0893 20.0038 60.0893C18.6131 60.0893 17.2546 60.2321 15.9433 60.504V5.8872H62.1451V16.8689C62.1451 18.491 63.4571 19.8068 65.0792 19.8121L76.1128 19.8457V84.9058H39.4211Z"
      fill="currentColor"
    />
    <path
      d="M28.4206 71.8627C27.2713 70.7134 25.4079 70.7134 24.2585 71.8627L20.0041 76.1164L15.7504 71.8627C14.6011 70.7134 12.7377 70.7134 11.5876 71.8627C10.4383 73.0121 10.4383 74.8762 11.5876 76.0255L15.842 80.2792L11.5876 84.5329C10.4383 85.6823 10.4383 87.5464 11.5876 88.6957C12.1626 89.2708 12.9164 89.5579 13.6694 89.5579C14.4224 89.5579 15.1761 89.2708 15.7504 88.6957L20.0041 84.4421L24.2578 88.6957C24.8328 89.2708 25.5858 89.5579 26.3396 89.5579C27.0926 89.5579 27.8463 89.2708 28.4206 88.6957C29.5699 87.5464 29.5699 85.6823 28.4206 84.5329L24.1669 80.2792L28.4206 76.0255C29.5707 74.8762 29.5707 73.0121 28.4206 71.8627V71.8627Z"
      fill="currentColor"
    />
    <path
      d="M26.7526 32.7793H48.664C50.2899 32.7793 51.608 31.4619 51.608 29.836C51.608 28.2102 50.2899 26.8928 48.664 26.8928H26.7526C25.1267 26.8928 23.8086 28.2102 23.8086 29.836C23.8086 31.4619 25.1267 32.7793 26.7526 32.7793Z"
      fill="currentColor"
    />
    <path
      d="M62.2048 38.6667H26.7526C25.1267 38.6667 23.8086 39.9849 23.8086 41.61C23.8086 43.2358 25.1267 44.5539 26.7526 44.5539H62.2048C63.8299 44.5539 65.1481 43.2358 65.1481 41.61C65.1481 39.9849 63.8299 38.6667 62.2048 38.6667Z"
      fill="currentColor"
    />
    <path
      d="M62.2048 50.4407H26.7526C25.1267 50.4407 23.8086 51.758 23.8086 53.3839C23.8086 55.0098 25.1267 56.3271 26.7526 56.3271H62.2048C63.8299 56.3271 65.1481 55.0098 65.1481 53.3839C65.1481 51.758 63.8299 50.4407 62.2048 50.4407Z"
      fill="currentColor"
    />
  </svg>
);

const Composed = memo(forwardRef(NoDataIcon));
Composed.displayName = 'NoDataIcon';

export default Composed;
