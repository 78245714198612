import { CancelIcon, TickIcon } from 'src/icons';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DesktopListContainer = styled.div`
  height: 100%;
  overflow-x: auto;
  padding-top: 60px;
`;

export const MobileListContainer = styled.div`
  padding: 40px 0;
`;

export const ExpiredIcon = styled(CancelIcon)`
  color: ${({ theme: { palette } }) => palette.colors.red};
  width: 21px;
`;

export const ValidIcon = styled(TickIcon)`
  color: ${({ theme: { palette } }) => palette.primary.light};
  width: 21px;
  path {
    stroke-width: 1px;
  }
`;
