import React, { forwardRef, memo, Ref, SVGProps } from 'react';

const ConfirmLockIcon = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => (
  <svg
    width={16}
    height={18}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C5.44205 0 3.36842 2.09686 3.36842 4.68347V6.81376C2.38743 6.82066 1.8263 6.86051 1.37939 7.09076C0.904034 7.33568 0.517558 7.72645 0.275352 8.20715C0 8.75367 0 9.46896 0 10.8997V13.795C0 15.2257 0 15.941 0.275352 16.4875C0.517558 16.9682 0.904034 17.359 1.37939 17.6039C1.9198 17.8824 2.62723 17.8824 4.04211 17.8824H11.9579C13.3728 17.8824 14.0802 17.8824 14.6206 17.6039C15.096 17.359 15.4824 16.9682 15.7246 16.4875C16 15.941 16 15.2257 16 13.795V10.8997C16 9.46896 16 8.75367 15.7246 8.20715C15.4824 7.72645 15.096 7.33568 14.6206 7.09076C14.1737 6.86051 13.6125 6.82066 12.6316 6.81376V4.68347C12.6316 2.09686 10.558 0 8 0ZM10.9474 6.81233V4.68347C10.9474 3.03745 9.62779 1.70308 8 1.70308C6.37221 1.70308 5.05263 3.03745 5.05263 4.68347V6.81233H10.9474ZM13.6185 10.7858L7.26552 15.7858L6.55068 16.3484L5.92312 15.6899L3.27606 12.9121L4.72394 11.5324L6.74343 13.6516L12.3815 9.21419L13.6185 10.7858Z"
      fill="currentColor"
    />
  </svg>
);

const Composed = memo(forwardRef(ConfirmLockIcon));
Composed.displayName = 'ConfirmLockIcon';

export default Composed;
