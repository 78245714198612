import { CSSProperties } from 'react';

import { ApiOpenTokenRecord } from '../../../interfaces/api.types';
import { ITheme } from '../../../theme/Theme';

export const getRowCellsStyle = (
  { valid }: ApiOpenTokenRecord,
  { palette }: ITheme,
): CSSProperties | undefined => {
  return {
    color: valid ? palette.primary.mainInvert : palette.colors.red,
  };
};
