import React, { forwardRef, memo, Ref, SVGProps } from 'react';

const DashboardIcon = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <path
      d="M24 20.139V13.1796C24 10.9032 24 9.76507 23.532 8.75901C23.0639 7.75296 22.1825 6.99678 20.4199 5.48441L19.0865 4.34043C15.714 1.44681 14.0277 0 12 0C9.97227 0 8.286 1.44681 4.91345 4.34043L3.58012 5.48441C1.81744 6.99678 0.936107 7.75296 0.468053 8.75901C0 9.76507 0 10.9032 0 13.1796V20.139C0 21.3384 0 21.938 0.202987 22.4111C0.47364 23.0417 0.992773 23.5429 1.64617 23.8041C2.13624 24 2.75749 24 4 24H4.44444C5.27108 24 5.68441 24 6.02352 23.9124C6.94376 23.6743 7.66256 22.9804 7.90913 22.0922C8 21.7649 8 21.3659 8 20.568V17.5651C8 15.4326 9.7908 13.7041 12 13.7041C14.2092 13.7041 16 15.4326 16 17.5651V20.568C16 21.3659 16 21.7649 16.0908 22.0922C16.3375 22.9804 17.0563 23.6743 17.9765 23.9124C18.3156 24 18.7289 24 19.5556 24H20C21.2425 24 21.8637 24 22.3539 23.8041C23.0072 23.5429 23.5264 23.0417 23.7971 22.4111C24 21.938 24 21.3384 24 20.139Z"
      fill="currentColor"
    />
  </svg>
);

const Composed = memo(forwardRef(DashboardIcon));
Composed.displayName = 'DashboardIcon';

export default Composed;
