import anime from 'animejs';
import React, { forwardRef, memo, Ref, SVGProps, useEffect, useRef } from 'react';

const Hell = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => {
  const flames = useRef<SVGPathElement>(null);
  const flames2 = useRef<SVGPathElement>(null);
  const glow = useRef<SVGPathElement>(null);

  useEffect(() => {
    const maxDebounce = 0.9;
    anime({
      targets: flames.current,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      direction: 'alternate',
      translateY: [maxDebounce * -1, maxDebounce, maxDebounce / -2, maxDebounce / 2, 0],
      loop: true,
    });
    const maxDebounce2 = 0.75;
    anime({
      targets: flames2.current,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      direction: 'alternate',
      translateY: [maxDebounce2 * -1, maxDebounce2, maxDebounce2 / -2, maxDebounce2 / 2, 0],
      loop: true,
      delay: 150,
    });
    anime({
      targets: glow.current,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      direction: 'alternate',
      opacity: [0.75, 0.6, 0.75, 0.85, 0.75],
      loop: true,
    });
  }, []);

  return (
    <svg
      width="100%"
      viewBox="0 0 276 319"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M88.946 208.054h93.649l10.513-97.952 16.244-33.59-.223-33-12.085-.949 1.616-10.948-14.672-13.233-13.11 3.523-4.092-10.513-30.904-6.723-34.684 6.723-4.927 10.513-11.829-3.345-13.847 13.212.848 10.791-9.911.948-5.876 48.686 19.388 23.346 13.902 92.511z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M181.168 118.932l-4.371-10.703-3.065-6.143 3.656-6.61-1.761-6.579.123-4.548-5.854-6.823-1.616.379-2.598-2.776-.323-4.125-.647-.948-1.304.847-4.125 7.994-2.821-2.442-.111-7.993 1.951-6.645-3.891-5.596-2.475-6.132-6.277 17.804-4.069 18.652-2.665-17.938-6.789-10.502-7.726 17.67-6.678-20.168-5.351 4.65 4.314 18.227-5.318 6.01-5.708-10.926-3.712 21.93-7.314-19.299-2.274 6.489-1.64 7.09-1.103 4.638 1.07 6.957-3.032 4.827-4.616 25.765-7.536-31.886-2.185-14.292L70.07 71.65l.112-1.717 18.139-29.967 15.942-11.216 10.692-10.691 18.886-1.94 21.494-1.56 13.758 11.85 15.128 19.778 20.737 35.051-1.304 29.7-6.913 14.337-4.693 6.433-4.582 4.95-5.575-15.441-.724-2.285z"
        fill="url(#prefix__paint1_linear)"
        ref={flames}
      />
      <path
        d="M64.553 116.122l.613 12.832-11.082-33.802 5.273-37.26 4.226-12.843 4.593-3.333 3.345-7.135 3.523-5.864 19.198-9.432 4.983-.457 3.479-3.289 10.557-6.165 17.091-1.829 25.118.915 11.762 6.254 4.526 5.786 8.039 1.316 18.596 14.17 1.984 4.258 6.087 2.665 7.091 13.434-1.951 18.15-8.919 33.524-4.371 16.221.201-12.241-1.806-13.378-13.523-23.557-7.815 3.935 2.909-10.134-.858-27.67-3.913 8.405-1.16 11.461-3.055 10.011-.579-8.718-1.873-8.707-2.152-15.385-3.958 1.784-3.969 1.65-.724-4.07-9.176-16.366-2.898 22.91-9.231 11.54-.413 13.857-9.844-9.91 2.118-13.546 7.67-15.419-8.696 1.784-6.042 9.287-3.178 3.835-4.058 1.862 1.606-10.536.658-7.202-3.992-5.753-3.099 10.792-6.388 6.545 2.531 18.495-9.867-7.637-8.027-2.698-4.136 5.151-8.808 18.774-5.14 6.333-3.98 4.415-.356 5.909 1.26 26.076z"
        fill="url(#prefix__paint2_linear)"
        ref={flames2}
      />
      <path d="M177.02 194.676H92.29v14.493h84.73v-14.493z" fill="url(#prefix__paint3_linear)" />
      <path
        d="M135.77 7.936V.132c-3.478.68-21.851 5.24-25.14 6.176-3.289.936-6.522 1.94-9.733 2.899l-1.84.546-.556 1.85a68.962 68.962 0 01-2.933 7.883l-2.764-.96a40.87 40.87 0 01-5.263-2.229l-1.739-.947-1.728 1.025A82.223 82.223 0 0067.91 29.42l-1.483 1.494.58 2.007c.713 2.453 1.37 4.816 1.794 7.09-2.34.1-4.749.112-7.235.179l-3.1.078-.211 2.932a129.236 129.236 0 002.642 38.173l4.939-.959a124.318 124.318 0 01-1.215-33.457c2.742 0 5.574.111 8.473-.067l3.478-.2-.19-3.546a46.238 46.238 0 00-1.973-10.123 74.918 74.918 0 0111.673-9.499c1.471.67 2.91 1.193 4.337 1.706l5.886 2.03 3.1 1.114 1.349-2.943a100.483 100.483 0 004.002-9.978c2.642-.792 5.251-1.583 7.804-2.33 3.222-.903 20.079-4.616 23.211-5.184z"
        fill="url(#prefix__paint4_radial)"
      />
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M65.813 47.045l-.078-1.761 8.461-.636a51.41 51.41 0 00-1.984-11.293l-.134-.48.346-.368A70.947 70.947 0 0185.568 21.36l.38-.246.412.157c1.572.58 3.21 1.114 4.938 1.716 2.52.837 5.118 1.706 7.481 2.665 1.681-3.392 3.17-6.876 4.46-10.435l.747-1.985.401-.133c6.172-2.055 18.825-3.595 25.252-4.605l5.574-.557c-6.219.973-23.986 4.795-29.956 6.79l-.502 1.136a95.996 95.996 0 01-4.872 11.361l-.39.713-.747-.312c-2.509-1.059-5.352-2.018-8.117-2.943-1.516-.513-3.088-1.037-4.56-1.572a68.889 68.889 0 00-12.096 10.235 52.512 52.512 0 011.94 12.096v.858l-10.1.747z"
        fill="url(#prefix__paint5_linear)"
      />
      <path
        d="M65.367 80.48a124.302 124.302 0 01-1.238-33.569v-.401h.49c2.576 0 5.486.111 8.44-.067l3.033-.167-.212-3.11a46.828 46.828 0 00-1.895-10.034l-.067-.257.19-.19a74.587 74.587 0 0111.694-9.554l.212-.134.212.1c1.471.659 2.876 1.116 4.303 1.695l8.652 2.966 1.17-2.542a98.39 98.39 0 003.958-9.855l.078-.223 1.115-.324c2.352-.702 4.693-1.404 7.024-2.073 2.33-.67 5.808-1.271 8.918-1.829l14.327-2.976V9.05c-.937.19-12.877 2.508-14.17 2.73-2.955.536-6.69 1.183-8.853 1.818-2.162.636-4.66 1.36-7.012 2.074l-.613.19a103.263 103.263 0 01-3.936 9.732l-1.505 3.345-9.42-3.311c-1.383-.502-2.743-.993-4.159-1.617a74.413 74.413 0 00-11.148 9.12 46.966 46.966 0 011.861 10.033l.212 3.992-3.913.223c-2.843.167-5.574.133-8.105.078a123.66 123.66 0 001.282 32.922l-.925.1z"
        fill="#F9F5DE"
      />
      <path
        d="M96.27 209.169c-2.106-6.266-3.823-12.654-5.34-19.098-1.516-6.444-2.553-12.988-3.534-19.521-.98-6.533-1.75-13.111-2.508-19.666l-1.115-9.845-.814-9.922v-.345l.245-.279c1.305-1.528 2.632-2.988 3.802-4.538a18.325 18.325 0 002.832-4.916l.312 1.014a45.096 45.096 0 00-6.879-4.036l-.513-.256v-.569c.075-6.534.62-13.054 1.628-19.51v.268a25.642 25.642 0 00-4.102-11.227l-.134-.19v-.245a39.64 39.64 0 01.312-6.142l.067-.692.691-.1c.68-.112 1.438-.335 2.297-.468l-.714 1.181a37.289 37.289 0 00-3.79-7.536l-1.394-2.23 2.453.88a30.535 30.535 0 0011.215 1.673l-.669 1.45a11.94 11.94 0 01-2.586-7.214v-.546l.424-.223.222-.178c.139-.129.288-.244.446-.346l-.423.758v-2.91l.803-.066a11.059 11.059 0 013.076.089l-.903.345c.128-.154.244-.318.346-.49v.078-.335c0-.49-.145-.97-.245-1.449a17.835 17.835 0 00-.848-2.82 30.83 30.83 0 00-1.248-2.687 22.516 22.516 0 00-1.539-2.531l-.479-.691.635-.435a14.204 14.204 0 005.664-7.904v.178a16.546 16.546 0 00-2.096-5.809 13.5 13.5 0 00-4.281-4.56c.934.515 1.802 1.14 2.586 1.862a13.69 13.69 0 012.108 2.43 17.024 17.024 0 012.586 5.943v.167a15.497 15.497 0 01-5.574 8.92l.156-1.116a22.55 22.55 0 011.84 2.62c.539.93.998 1.903 1.37 2.91a22.79 22.79 0 01.904 3.088c.111.535.211 1.06.278 1.595v.434c.012.286-.049.57-.178.825a5.24 5.24 0 01-.513.78l-.334.458-.569-.112a9.22 9.22 0 00-2.553 0l.814-.892V67.101l-.413.212-.211.179c-.123.089-.223.2-.446.345l.412-.769a10.18 10.18 0 002.23 6.098l1.115 1.405h-1.795A32.33 32.33 0 0179.537 72.8l1.048-1.316a38.865 38.865 0 013.969 7.904l.356 1.015-1.115.167c-.68.1-1.427.323-2.296.457l.758-.791a38.83 38.83 0 00-.312 5.841l-.145-.434a27.638 27.638 0 014.359 12.04v.268a135.107 135.107 0 00-1.639 19.242l-.502-.825a47.582 47.582 0 017.147 4.204l.546.39-.223.635a20.753 20.753 0 01-3.088 5.396c-1.238 1.628-2.587 3.122-3.869 4.616l.212-.625.803 9.811 1.115 9.833c.758 6.556 1.572 13.1 2.508 19.622.937 6.522 2.14 12.988 3.122 19.499.98 6.511 2.43 12.966 3.98 19.421z"
        fill="#F9F5DE"
      />
      <path
        d="M65.924 44.447l-2.42 25.42 7.058 5.072c-3.657 12.264-5.664 28.663-7.057 46.691-3.724 5.797-5.83 13.144-6.69 21.717-3.344 6.567-5.273 14.404-6.521 22.922l-7.526-26.344-4.125-26.757 1.115-17.136 6.154-24.125 4.103-8.785 6.968-10.759 5.195-7.247 3.746-.669z"
        fill="url(#prefix__paint6_linear)"
      />
      <path
        d="M42.969 134.473a57.103 57.103 0 018.919-28.05c.334 4.002.535 8.239.88 12.264a64.93 64.93 0 016.913-13.2c-.732-8.223-.84-16.49-.323-24.728.813-12.61 2.385-25.174 5.964-37.292a92.635 92.635 0 00-24.404 42.677c-3.925 15.909-2.843 32.632 2.051 48.329z"
        fill="url(#prefix__paint7_linear)"
      />
      <path
        d="M94.387 44.86a14.86 14.86 0 01-7.113 9.41 4.627 4.627 0 01-3.278-3.546 19.5 19.5 0 006.69-6.533 15.262 15.262 0 00-3.122-9.632c5.775 3.4 6.823 10.301 6.823 10.301z"
        fill="#134B7D"
      />
      <path
        d="M79.436 71.907a31.328 31.328 0 0011.862 1.828 11.149 11.149 0 01-2.385-6.689c.211-.123.468-.401.669-.524v-2.085a9.868 9.868 0 012.809.078c.2-.278.557-.691.524-1.025a21.25 21.25 0 00-7.202-13.468 54.04 54.04 0 00-7.19 1.327A18.081 18.081 0 0072 54.626a12.72 12.72 0 00.948 7.804 38.251 38.251 0 00-2.375 8.685 21.035 21.035 0 007.726 2.04c.415-.382.795-.8 1.137-1.248z"
        fill="url(#prefix__paint8_linear)"
      />
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M88.913 67.08c.211-.123.468-.402.669-.525V64.47a9.868 9.868 0 012.809.079c.2-.28.557-.692.524-1.026a21.072 21.072 0 00-5.407-11.695 5.24 5.24 0 00-2.118.758 49.059 49.059 0 012.352 6.845c-.892-.212-1.75-.568-2.642-.78a9.898 9.898 0 00-1.85 2.007 23.943 23.943 0 002.229 3.924 7.28 7.28 0 00-.814 2.754c-.156 2.497 1.773 4.604 3.846 6.365.914 0 1.829.056 2.754 0a11.148 11.148 0 01-2.352-6.622z"
        fill="url(#prefix__paint9_linear)"
      />
      <path
        d="M91.789 43.466a14.839 14.839 0 01-5.875 10.224 4.583 4.583 0 01-3.702-3.1 19.489 19.489 0 005.82-7.324 15.207 15.207 0 00-4.303-9.131c6.165 2.631 8.06 9.331 8.06 9.331z"
        fill="url(#prefix__paint10_linear)"
      />
      <path
        d="M82.525 51.237a18.25 18.25 0 005.953-7.737 15.953 15.953 0 00-2.865-7.414 14.604 14.604 0 013.779 7.57 29.364 29.364 0 01-5.452 8.975c.57.474 1.214.851 1.907 1.115a9.544 9.544 0 01-3.601 0 7.547 7.547 0 00-3.59 4.192 15.207 15.207 0 012.107 5.853c.309 2.07.472 4.16.49 6.254a12.263 12.263 0 002.32 2.319 10.935 10.935 0 01-4.226-.825 44.927 44.927 0 00-5.574-7.302c.11-.413.182-.834.212-1.26a13.802 13.802 0 01-.758-7.804 22.911 22.911 0 018.918-3.724c.078-.145.313-.111.38-.212z"
        fill="url(#prefix__paint11_linear)"
      />
      <path
        d="M88.946 209.136c-12.82-2.754-34.315-23.045-31.216-33.97 2.787-15.051 9.565-43.235 7.737-73.113a83.913 83.913 0 01-5.797-19.388 53.38 53.38 0 019.41-9.22c.407-4.679.129-9.392-.826-13.991 6.299 4.46 12.855 13.133 15.352 20.279-.691.1-1.617.368-2.297.468a37.237 37.237 0 00-.312 5.998 26.758 26.758 0 014.237 11.617c-2.03 11.84-2.23 28.797-.658 42.766 2.597 22.967 5.206 46.357 11.661 68.554h-7.29z"
        fill="url(#prefix__paint12_linear)"
      />
      <path
        d="M179.852 209.459c12.821-2.754 34.316-23.044 31.216-33.97-2.787-15.051-9.565-43.234-7.737-73.113a83.918 83.918 0 005.798-19.387 53.396 53.396 0 00-9.41-9.22 48.285 48.285 0 01.825-13.992c-6.299 4.46-12.854 13.133-15.352 20.28.692.1 1.617.367 2.297.468.265 1.987.37 3.993.312 5.998a26.745 26.745 0 00-4.236 11.617c2.029 11.84 2.229 28.797.657 42.766-2.597 22.966-5.206 46.356-11.661 68.553h7.291z"
        fill="url(#prefix__paint13_linear)"
      />
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M84.61 140.583a169.169 169.169 0 01-.904-12.509 16.882 16.882 0 00-3.344.401 179.474 179.474 0 001.95 28.518c-.668-2.374-1.75-4.582-2.419-6.956 1.115 15.608 1.595 32.331 3.746 48.697a44.243 44.243 0 001.583-11.862 104.293 104.293 0 003.445 12.609 37.29 37.29 0 005.073 9.677h2.53c-6.454-22.219-9.063-45.609-11.66-68.575z"
        fill="url(#prefix__paint14_linear)"
      />
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M83.271 118.33l.324-.089c.05-6.841.608-13.668 1.672-20.425A26.758 26.758 0 0081.031 86.2c-.058-2.004.046-4.01.312-5.998.68-.1 1.605-.367 2.296-.468a35.674 35.674 0 00-2.854-6.02 28.312 28.312 0 00-3.645 5.15 16.187 16.187 0 00-.993 4.984s4.08 8.428 6.088 12.72a58.597 58.597 0 001.036 21.763z"
        fill="url(#prefix__paint15_linear)"
      />
      <path
        d="M82.903 119.222c-.267-3.824-.624-7.727-.891-11.55a14.486 14.486 0 01-2.074 2.541 32.156 32.156 0 01-2.865-4.582 41.975 41.975 0 01.925-14.727 28.172 28.172 0 00-3.757 5.396c-1.115-3.545-2.386-7.169-3.49-10.725a43.292 43.292 0 013.613-8.194c1.293.836 2.798 1.683 4.091 2.52-1.204-1.183-2.486-2.398-3.679-3.58a46.832 46.832 0 011.951-5.853 45.577 45.577 0 00-6.812-8.328 32.2 32.2 0 01.368 12.264 123.816 123.816 0 00-8.752 7.804 74.484 74.484 0 008.473 25.965c2.576 4.683 7.994 8.919 12.9 11.049z"
        fill="url(#prefix__paint16_linear)"
      />
      <path
        d="M70.517 93.702a108.375 108.375 0 009.89 21.986 55.19 55.19 0 0110.222 5.574c-1.326 3.701-4.236 6.689-6.8 9.744-9.9-5.574-17.448-13.144-23.936-22.498-.524-8.373-.535-17.537-.223-25.92a16.936 16.936 0 017.09-.792c1.338 3.411 2.732 8.428 3.757 11.906z"
        fill="url(#prefix__paint17_linear)"
      />
      <path
        d="M83.728 34.135c1.016.354 1.979.842 2.866 1.45a14.28 14.28 0 012.408 2.162 15.729 15.729 0 013.21 5.575v.178a14.18 14.18 0 01-1.95 5.864 13.378 13.378 0 01-4.293 4.326 16.957 16.957 0 003.702-4.671 15.82 15.82 0 001.739-5.575v.167a16.918 16.918 0 00-1.215-2.831 18.797 18.797 0 00-1.684-2.598 13.38 13.38 0 00-2.151-2.23 14.07 14.07 0 00-2.632-1.817zM90.808 121.775a58.09 58.09 0 00-10.591-5.664l-.134-.067-.078-.133a119.918 119.918 0 01-7.994-16.512 88.559 88.559 0 01-2.787-8.751c-.825-2.932-1.65-5.864-2.665-8.73l.19.145a17.49 17.49 0 00-7.08.569 16.556 16.556 0 017.114-1.015h.133v.134c1.182 2.832 2.085 5.753 2.988 8.674l1.405 4.37a81.333 81.333 0 001.527 4.281 130.784 130.784 0 007.972 16.444l-.212-.2a60.2 60.2 0 0110.78 5.775l-.568.68zM83.216 79.844a35.122 35.122 0 00-2.71-5.752 55.522 55.522 0 00-3.534-5.307 63.448 63.448 0 00-4.08-4.905 58.165 58.165 0 00-4.604-4.46 28.763 28.763 0 015.094 3.98 43.614 43.614 0 014.337 4.839 42.742 42.742 0 016.333 11.316l-.836.29z"
        fill="#F9F5DE"
      />
      <path
        d="M81.175 64.125a7.715 7.715 0 00-.947-4.827c-1.048-1.885-3.936-2.643-6.266-2.955a34.559 34.559 0 002.542 4.393 10.624 10.624 0 004.671 3.389z"
        fill="url(#prefix__paint18_linear)"
      />
      <path
        d="M75.768 57.547a5.663 5.663 0 014.148 3.902 2.163 2.163 0 01-1.662-1.995 9.475 9.475 0 00-2.486-1.907zm9.287 1.416c0 .067.09.134.123.2.835.24 1.643.57 2.408.982a8.92 8.92 0 01-.2 1.215 5.341 5.341 0 001.672-2.085 13.714 13.714 0 00-4.048-1.048 9.82 9.82 0 00-1.438 1.472c.477-.284.981-.52 1.505-.702l-.022-.034zm4.46 7.559a6.333 6.333 0 00-4.114.234c1.16-.022 2.318.078 3.456.301.262-.134.506-.303.725-.502l-.067-.033zM88.4 67.916a7.38 7.38 0 01-1.918-.078c.305.233.63.438.97.613.352.146.736.2 1.115.156a2.529 2.529 0 01-.045-.658l-.122-.033zm-4.08 0a8.183 8.183 0 013.221-5.073 4.783 4.783 0 011.684.245c.278-.267.546-.513.825-.791-.36.056-.711.153-1.048.29-.546 0-1.227 0-1.762-.067a7.692 7.692 0 00-2.798 5.384l-.123.012z"
        fill="url(#prefix__paint19_linear)"
      />
      <path
        d="M86.148 126.145c-9.008-4.671-18.775-14.371-22.944-19.666a130.564 130.564 0 01-1.929-21.65c.312-.458.758-1.015 1.115-1.461-.78.122-1.438.278-2.23.401.2 8.094.405 16.192.613 24.293a94.668 94.668 0 0022.588 20.982c.87-.87 1.895-2.029 2.787-2.899z"
        fill="url(#prefix__paint20_linear)"
      />
      <path
        d="M69.57 40.39c-1.115.59-2.341 1.114-3.501 1.772l-3.478 1.806-.201.2a37.974 37.974 0 00-4.671 4.706c-1.438 1.672-2.71 3.433-3.958 5.195a128.807 128.807 0 00-3.59 5.373l-3.39 5.34-.077.134-.067.134a122.909 122.909 0 00-6.087 18.563 175.262 175.262 0 00-3.467 19.109v.189c-.096 5.791.228 11.581.97 17.325a158.424 158.424 0 003.065 17.013c1.294 5.574 2.888 11.149 4.672 16.578 1.783 5.429 3.723 10.848 5.92 16.143-1.695-5.474-3.345-10.959-4.75-16.477a279.999 279.999 0 01-3.701-16.723c-1.06-5.575-1.895-11.216-2.42-16.79a137.045 137.045 0 01-.501-16.835c.948-6.276 2.163-12.498 3.69-18.618a118.564 118.564 0 015.976-17.838l6.6-10.725c1.115-1.784 2.308-3.512 3.512-5.218a40.96 40.96 0 013.902-4.715l.501-.335c-.457.747-.892 1.505-1.326 2.23a96.722 96.722 0 00-2.631 5.084 103.822 103.822 0 00-2.375 5.206c-.747 1.75-1.472 3.5-2.152 5.296V63.723l-2.698 11.472c-.858 3.836-1.717 7.66-2.475 11.54v.178a141.812 141.812 0 00-.256 15.073c.178 5.009.472 9.993.88 14.95.179 2.464.39 5.006.781 7.492.39 2.486.903 4.939 1.46 7.369 1.115 4.872 2.509 9.655 3.869 14.493-.892-4.905-1.962-9.777-2.754-14.671a94.845 94.845 0 01-1.371-14.783c-.123-4.973-.279-9.934-.245-14.884.033-4.95.178-9.81.68-14.66l2.642-11.35.145-.58c-.223 1.684-.446 3.345-.636 5.062v.19c0 3.701.301 7.38.692 11.026.39 3.645.947 7.257 1.516 10.859.569 3.601 1.115 7.19 2.074 10.769a77.39 77.39 0 003.857 10.29c-.858-3.545-1.984-7.034-2.52-10.569a119.647 119.647 0 01-.646-5.373l-.58-5.407c-.401-3.612-.77-7.213-1.003-10.815-.234-3.6-.402-7.09-.335-10.647.981-6.443 2.018-12.932 3.088-19.387a243.08 243.08 0 002.632-9.086c.434-1.539.825-3.088 1.226-4.638.29-1.204.59-2.42.87-3.635l.167-.111c1.115-.736 2.151-1.494 3.222-2.23a162.59 162.59 0 006.343-4.637 164.657 164.657 0 00-7.09 3.366z"
        fill="url(#prefix__paint21_linear)"
      />
      <path
        d="M64.018 45.975a97.107 97.107 0 00-7.347 10.09c-2.23 3.5-4.367 7.071-6.41 10.713a140.251 140.251 0 00-5.887 17.916 165.369 165.369 0 00-3.601 18.507v-.067c.078 5.262.3 10.524.702 15.775.401 5.251.992 10.491 1.706 15.698.713 5.206 1.661 10.413 2.776 15.608a219.921 219.921 0 003.89 15.318c-1.672-5.006-3.076-10.034-4.314-15.229a194 194 0 01-3.032-15.541 226.053 226.053 0 01-1.84-15.742 241.913 241.913 0 01-.78-15.831v-.067a167.09 167.09 0 013.712-18.585 125.519 125.519 0 016.12-17.938c2.108-3.601 4.27-7.169 6.623-10.614a91.403 91.403 0 017.682-10.011z"
        fill="#F9F5DE"
      />
      <path
        d="M60.16 61.483a672.455 672.455 0 00-3.1 13.133c-1.003 4.37-1.917 8.774-2.82 13.177v-.066c-.178 4.905-.29 9.807-.334 14.705 0 4.894-.1 9.811 0 14.694.1 4.883.58 9.766 1.226 14.627.647 4.86 1.494 9.677 2.475 14.493-1.204-4.761-2.23-9.566-2.999-14.426a124.129 124.129 0 01-1.494-14.672 347.276 347.276 0 010-14.727c.104-4.906.223-9.83.357-14.772.937-4.404 1.996-8.774 3.088-13.145a264.913 264.913 0 013.601-13.021z"
        fill="#F9F5DE"
      />
      <path
        d="M76.66 37l-9.599 7.169.078-.123c-1.516 5.809-2.999 11.617-4.56 17.414-1.025 6.801-1.995 13.613-3.054 20.402v-.1L60.75 97.37l1.115 15.608 2.319 10.614-2.72-10.524-1.46-15.609-1.328-15.608v-.1c1.004-6.8 2.096-13.59 3.144-20.39 1.595-5.887 3.267-11.618 4.895-17.415v-.078L76.66 37z"
        fill="#F9F5DE"
      />
      <path
        d="M135.771 7.936V.132c3.478.68 19.565 5.24 22.854 6.176 3.289.936 6.522 1.94 9.733 2.899l1.84.546.557 1.85a68.905 68.905 0 002.932 7.883l2.765-.96a40.918 40.918 0 005.229-2.229l1.783-.947 1.717 1.025a82.22 82.22 0 0116.166 13.044l1.516 1.494-.58 2.007c-.713 2.453-1.371 4.816-1.795 7.09 2.342.1 4.75.112 7.236.179l3.099.078.212 2.932a129.608 129.608 0 01-2.598 38.173l-4.938-.959a124.325 124.325 0 001.237-33.446c-2.743 0-5.574.112-8.473-.067l-3.478-.2.189-3.546a46.887 46.887 0 011.918-10.145 74.906 74.906 0 00-11.673-9.499c-1.471.67-2.91 1.193-4.337 1.706l-5.886 2.03-3.1 1.114-1.348-2.943a100.316 100.316 0 01-3.981-9.911c-2.642-.792-5.251-1.584-7.859-2.33-3.233-.96-17.805-4.672-20.937-5.24z"
        fill="url(#prefix__paint22_radial)"
      />
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M203.443 47.045l.134-1.772-8.462-.636a52.041 52.041 0 011.984-11.294l.134-.479-.346-.368a70.952 70.952 0 00-13.144-11.148l-.379-.246-.412.156c-1.572.58-3.211 1.115-4.939 1.717-2.52.836-5.106 1.706-7.481 2.665a100.98 100.98 0 01-4.404-10.458l-.758-1.984-.401-.134a117.568 117.568 0 00-18.964-4.57l-.268 1.772a113.37 113.37 0 0118.329 4.46l.446 1.192a95.847 95.847 0 004.872 11.36l.39.714.747-.312c2.508-1.06 5.362-2.018 8.116-2.943 1.516-.513 3.088-1.037 4.56-1.572a69.328 69.328 0 0112.107 10.234 51.966 51.966 0 00-1.951 12.097v.858l10.09.691z"
        fill="url(#prefix__paint23_linear)"
      />
      <path
        d="M203.889 80.48l-.881-.145a123.656 123.656 0 001.282-32.922c-2.486.056-5.262.09-8.105-.078l-3.913-.223.223-3.991a46.258 46.258 0 011.851-10.034 74.44 74.44 0 00-11.205-9.12c-1.427.625-2.832 1.115-4.147 1.617l-9.387 3.233-1.505-3.344a99.923 99.923 0 01-3.925-9.733l-.669-.2c-2.33-.703-4.649-1.406-6.968-2.063-2.319-.658-5.897-1.282-8.852-1.818-1.293-.223-10.981-2.419-11.918-2.608V7.936l12.074 2.854c3.133.557 6.69 1.182 8.919 1.828 2.23.647 4.638 1.36 6.979 2.063l1.115.334.078.223a96.38 96.38 0 003.98 9.978l1.115 2.587 8.607-2.977c1.371-.49 2.832-1.026 4.303-1.683l.212-.1.212.133a75.7 75.7 0 0111.74 9.543l.189.19-.078.256A46.796 46.796 0 00193.32 43.2l-.167 3.11 3.032.168c2.954.178 5.875.122 8.44.067h.468v.401a124.734 124.734 0 01-1.204 33.535z"
        fill="#F9F5DE"
      />
      <path
        d="M172.985 209.169a276.328 276.328 0 003.98-19.376c.981-6.511 2.229-12.978 3.121-19.499.892-6.522 1.751-13.067 2.509-19.622l1.115-9.833.802-9.811.212.624c-1.282-1.494-2.631-2.988-3.868-4.615a20.772 20.772 0 01-3.089-5.396l-.223-.636.547-.39a47.63 47.63 0 017.146-4.203l-.502.825a133.52 133.52 0 00-1.638-19.242v-.268a27.61 27.61 0 014.381-11.996l-.145.435a38.805 38.805 0 00-.312-5.842l.758.791c-.859-.133-1.617-.356-2.297-.457l-1.115-.167.357-1.014a38.897 38.897 0 013.969-7.905l1.048 1.316a32.33 32.33 0 01-11.862 1.772h-1.795l1.137-1.505a10.183 10.183 0 002.23-6.098l.412.77a3.836 3.836 0 01-.434-.346l-.223-.179-.413-.212V64.46l.814.892a9.22 9.22 0 00-2.553 0l-.569.112-.334-.457a5.2 5.2 0 01-.513-.78 1.823 1.823 0 01-.178-.826l.055-.435a16.49 16.49 0 01.279-1.594c.228-1.05.53-2.081.903-3.088.373-1.008.832-1.981 1.371-2.91a22.697 22.697 0 011.84-2.62l.156 1.115a15.43 15.43 0 01-5.574-8.919v-.167a17.022 17.022 0 012.586-5.942 13.73 13.73 0 012.107-2.43 13.224 13.224 0 012.498-1.851 13.49 13.49 0 00-4.281 4.56c-.516.899-.949 1.843-1.294 2.82-.351.97-.609 1.97-.769 2.988v-.178a14.204 14.204 0 005.664 7.904l.635.435-.479.69a22.534 22.534 0 00-1.539 2.532c-.457.88-.881 1.772-1.249 2.686a17.886 17.886 0 00-1.092 4.27v.257a5.5 5.5 0 00.346.49l-.892-.345a10.978 10.978 0 013.066-.09l.802.067v2.91l-.423-.758c.158.102.308.217.446.346l.222.178.435.223v.546a11.934 11.934 0 01-2.631 7.18l-.669-1.45c3.814.148 7.622-.42 11.227-1.671l2.442-.881-1.394 2.23a37.299 37.299 0 00-3.791 7.536l-.713-1.182c.858.134 1.616.357 2.297.468l.691.1.067.692c.268 2.036.373 4.09.312 6.143v.279l-.134.2a25.823 25.823 0 00-4.103 11.227v-.279a136.359 136.359 0 011.661 19.499v.569l-.512.256a45.012 45.012 0 00-6.879 4.036l.312-1.014a18.31 18.31 0 002.832 4.916c1.115 1.55 2.497 3.01 3.801 4.538l.246.278v.346l-.814 9.922-1.115 9.845c-.758 6.555-1.572 13.11-2.508 19.666-.937 6.555-1.907 13.099-3.523 19.521-1.617 6.422-3.289 12.81-5.396 19.109z"
        fill="#F9F5DE"
      />
      <path
        d="M203.331 44.447l2.386 25.42-7.057 5.072c3.657 12.264 5.664 28.663 7.057 46.691 3.724 5.797 5.831 13.144 6.689 21.717 3.345 6.567 5.274 14.404 6.533 22.922l7.582-26.344 4.125-26.757-1.115-17.136-6.154-24.125-4.137-8.785-6.967-10.759-5.185-7.247-3.757-.669z"
        fill="url(#prefix__paint24_linear)"
      />
      <path
        d="M226.275 134.473a57.105 57.105 0 00-8.919-28.05c-.334 4.002-.535 8.239-.88 12.264a64.915 64.915 0 00-6.924-13.2c.732-8.223.84-16.49.324-24.728-.814-12.61-2.386-25.174-5.965-37.292a92.635 92.635 0 0124.404 42.632c3.947 15.954 2.877 32.677-2.04 48.374z"
        fill="url(#prefix__paint25_linear)"
      />
      <path
        d="M174.869 44.86a14.859 14.859 0 007.113 9.41 4.595 4.595 0 003.277-3.546 19.6 19.6 0 01-6.689-6.533 15.258 15.258 0 013.122-9.632c-5.775 3.4-6.823 10.301-6.823 10.301z"
        fill="#134B7D"
      />
      <path
        d="M189.819 71.907a31.293 31.293 0 01-11.862 1.828 11.151 11.151 0 002.386-6.689c-.212-.123-.468-.401-.669-.524v-2.085a9.87 9.87 0 00-2.81.078c-.2-.278-.557-.691-.524-1.025a21.254 21.254 0 017.202-13.468 54.01 54.01 0 017.191 1.327c2.389.643 4.62 1.77 6.556 3.31.521 2.637.189 5.37-.948 7.805a38.24 38.24 0 012.375 8.685 21.027 21.027 0 01-7.693 1.928 9.762 9.762 0 01-1.204-1.17z"
        fill="url(#prefix__paint26_linear)"
      />
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M180.343 67.08c-.212-.123-.468-.402-.669-.525V64.47a9.87 9.87 0 00-2.81.079c-.2-.28-.557-.692-.524-1.026a21.185 21.185 0 015.341-11.695 5.242 5.242 0 012.118.758 49.048 49.048 0 00-2.341 6.845c.892-.212 1.739-.568 2.631-.78a9.906 9.906 0 011.85 2.007 23.124 23.124 0 01-2.229 3.924c.451.855.728 1.79.814 2.754.156 2.497-1.773 4.604-3.847 6.365-.914 0-1.828.056-2.753 0a11.153 11.153 0 002.419-6.622z"
        fill="url(#prefix__paint27_linear)"
      />
      <path
        d="M177.466 43.466a14.83 14.83 0 005.887 10.224 4.58 4.58 0 003.69-3.1 19.482 19.482 0 01-5.808-7.324 15.207 15.207 0 014.292-9.131c-6.165 2.631-8.061 9.331-8.061 9.331z"
        fill="url(#prefix__paint28_linear)"
      />
      <path
        d="M186.731 51.237a18.12 18.12 0 01-5.942-7.737 15.81 15.81 0 012.854-7.414 14.688 14.688 0 00-3.78 7.57 29.375 29.375 0 005.452 8.975 6.509 6.509 0 01-1.906 1.115 9.598 9.598 0 003.612 0 7.57 7.57 0 013.579 4.136 15.203 15.203 0 00-2.108 5.853 45.153 45.153 0 00-.49 6.254 12.275 12.275 0 01-2.364 2.375 11.027 11.027 0 004.237-.825 44.28 44.28 0 015.574-7.302 5.779 5.779 0 01-.2-1.26 13.914 13.914 0 00.747-7.804 22.842 22.842 0 00-8.919-3.724c-.045-.145-.324-.111-.346-.212z"
        fill="url(#prefix__paint29_linear)"
      />
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M184.646 140.583c.446-3.992.747-8.228.903-12.509 1.126.022 2.246.156 3.345.401a179.521 179.521 0 01-1.951 28.518c.669-2.374 1.75-4.582 2.419-6.956-1.115 15.608-1.583 32.331-3.746 48.697a44.263 44.263 0 01-1.583-11.862 104.42 104.42 0 01-3.467 12.587 37.316 37.316 0 01-5.073 9.677h-2.508c6.466-22.197 9.064-45.587 11.661-68.553z"
        fill="url(#prefix__paint30_linear)"
      />
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M185.984 118.33l-.323-.089a137.773 137.773 0 00-1.673-20.425 26.608 26.608 0 014.248-11.617 39.951 39.951 0 00-.312-5.998c-.691-.1-1.617-.367-2.308-.468a35.72 35.72 0 012.854-6.02 28.304 28.304 0 013.646 5.15c.591 1.6.926 3.281.992 4.984 0 0-4.103 8.428-6.087 12.676a58.6 58.6 0 01-1.037 21.807z"
        fill="url(#prefix__paint31_linear)"
      />
      <path
        d="M186.363 119.222c.256-3.824.613-7.727.881-11.55a14.5 14.5 0 002.073 2.541 32.161 32.161 0 002.866-4.582c.563-4.925.25-9.911-.926-14.727a28.126 28.126 0 013.802 5.396c1.115-3.545 2.386-7.169 3.49-10.725a43.327 43.327 0 00-3.613-8.194c-1.293.836-2.798 1.683-4.08 2.52 1.193-1.183 2.475-2.398 3.668-3.58a45.131 45.131 0 00-1.951-5.853 45.561 45.561 0 016.812-8.328 32.18 32.18 0 00-.368 12.264 123.671 123.671 0 018.751 7.804 74.695 74.695 0 01-8.461 25.965c-2.631 4.683-8.016 8.919-12.944 11.049z"
        fill="url(#prefix__paint32_linear)"
      />
      <path
        d="M198.738 93.702a108.332 108.332 0 01-9.889 21.986 55.19 55.19 0 00-10.223 5.574c1.327 3.701 4.236 6.689 6.801 9.744 9.9-5.574 17.447-13.144 23.936-22.498.524-8.373.535-17.537.223-25.92a16.938 16.938 0 00-7.091-.792c-1.327 3.411-2.731 8.428-3.757 11.906z"
        fill="url(#prefix__paint33_linear)"
      />
      <path
        d="M185.527 34.135a14.09 14.09 0 00-2.587 1.795 13.363 13.363 0 00-2.151 2.23 18.783 18.783 0 00-1.684 2.597c-.49.905-.897 1.853-1.215 2.832v-.167c.22 1.95.807 3.841 1.728 5.574a16.79 16.79 0 003.713 4.671 13.297 13.297 0 01-4.304-4.325 14.189 14.189 0 01-2.029-5.842v-.178a15.963 15.963 0 011.338-2.966 16.503 16.503 0 011.895-2.642 14.278 14.278 0 012.408-2.163 12.166 12.166 0 012.888-1.416zM177.924 121.095a60.204 60.204 0 0110.736-5.798l-.212.201a130.742 130.742 0 007.982-16.389c.547-1.404 1.115-2.843 1.528-4.28l1.338-4.36c.903-2.92 1.806-5.842 2.987-8.673l.056-.134h.134c2.417-.188 4.845.159 7.113 1.014a17.462 17.462 0 00-7.08-.568l.19-.145c-1.015 2.865-1.84 5.797-2.665 8.73a88.505 88.505 0 01-2.787 8.75 119.883 119.883 0 01-7.993 16.512l-.078.134-.134.067a58.12 58.12 0 00-10.591 5.663l-.524-.724zM185.204 79.588a42.741 42.741 0 016.332-11.316 43.574 43.574 0 014.337-4.838 28.44 28.44 0 015.106-3.98 56.489 56.489 0 00-4.616 4.46 63.541 63.541 0 00-4.08 4.904 55.387 55.387 0 00-3.534 5.307 35.14 35.14 0 00-2.709 5.72l-.836-.257z"
        fill="#F9F5DE"
      />
      <path
        d="M188.08 64.125a7.713 7.713 0 01.903-4.827c1.048-1.885 3.935-2.643 6.266-2.955a34.716 34.716 0 01-2.542 4.393 10.627 10.627 0 01-4.627 3.389z"
        fill="url(#prefix__paint34_linear)"
      />
      <path
        d="M190.979 59.487a2.169 2.169 0 01-1.662 1.996 5.659 5.659 0 014.148-3.902 9.468 9.468 0 00-2.486 1.906zm-5.307.212a9.86 9.86 0 00-1.449-1.438c-1.395.14-2.76.494-4.047 1.048a5.335 5.335 0 001.672 2.085 8.922 8.922 0 01-.201-1.215 12.14 12.14 0 012.408-.982c0-.067.089-.133.123-.2.52.184 1.02.419 1.494.702zm-5.34 7.392a16.334 16.334 0 013.456-.301 6.333 6.333 0 00-4.114-.235c.196.206.417.386.658.536zm.234 1.605c.379.044.762-.01 1.115-.156a6.46 6.46 0 00.97-.613 7.386 7.386 0 01-1.918.078c-.023.237-.079.47-.167.691zm1.349-6.132c-.547 0-1.227 0-1.773.067a4.711 4.711 0 00-1.048-.29c.279.279.546.524.825.792a4.781 4.781 0 011.684-.245 8.253 8.253 0 013.233 5.072 7.81 7.81 0 00-2.932-5.452l.011.056z"
        fill="url(#prefix__paint35_linear)"
      />
      <path
        d="M183.108 126.145c9.008-4.671 18.774-14.371 22.944-19.666a130.622 130.622 0 001.917-21.65c-.312-.458-.758-1.015-1.115-1.461.781.122 1.438.278 2.23.401-.201 8.094-.405 16.192-.613 24.293a94.672 94.672 0 01-22.587 20.982c-.859-.87-1.873-2.029-2.776-2.899z"
        fill="url(#prefix__paint36_linear)"
      />
      <path
        d="M199.686 40.39c1.115.59 2.341 1.114 3.5 1.772l3.479 1.806.145.112a38.89 38.89 0 014.682 4.705c1.427 1.672 2.698 3.433 3.947 5.195a130.012 130.012 0 013.59 5.373l3.433 5.396.078.134.067.134a122.912 122.912 0 016.088 18.562c1.508 6.301 2.665 12.68 3.467 19.109v.19a119.737 119.737 0 01-.97 17.325 158.519 158.519 0 01-3.066 17.013c-1.293 5.574-2.888 11.148-4.671 16.578-1.784 5.429-3.724 10.847-5.92 16.143 1.694-5.474 3.344-10.959 4.749-16.478a279.339 279.339 0 003.701-16.723c1.06-5.574 1.907-11.182 2.431-16.756.517-5.596.685-11.218.501-16.835-.947-6.276-2.162-12.497-3.69-18.618a118.55 118.55 0 00-5.975-17.838l-6.6-10.725c-1.115-1.784-2.308-3.512-3.501-5.217a41.056 41.056 0 00-3.913-4.716l-.502-.335c.457.747.892 1.505 1.327 2.23.925 1.672 1.817 3.345 2.631 5.084.814 1.739 1.628 3.456 2.386 5.206.758 1.75 1.46 3.5 2.14 5.296V63.723l2.631 11.495c.859 3.835 1.717 7.66 2.475 11.539v.178c.353 5.016.438 10.048.257 15.073a321.41 321.41 0 01-.881 14.951c-.178 2.463-.39 5.005-.78 7.491-.391 2.487-.904 4.939-1.461 7.37-1.115 4.872-2.497 9.654-3.869 14.493.892-4.905 1.963-9.777 2.765-14.672a96.065 96.065 0 001.349-14.738c.123-4.973.279-9.934.246-14.884-.034-4.95-.179-9.81-.68-14.66l-2.565-11.416-.145-.558c.223 1.684.446 3.345.636 5.062v.19c0 3.7-.29 7.38-.691 11.025-.402 3.646-.948 7.258-1.517 10.859-.568 3.601-1.114 7.191-2.073 10.77a77.528 77.528 0 01-3.858 10.29c.859-3.545 1.985-7.035 2.52-10.569.256-1.773.468-3.568.647-5.374l.579-5.407c.402-3.612.77-7.213 1.004-10.814.234-3.601.39-7.113.323-10.647a1491.336 1491.336 0 00-3.088-19.388 242.315 242.315 0 01-2.631-9.086c-.435-1.538-.825-3.088-1.227-4.638-.29-1.204-.591-2.419-.869-3.634l-.167-.111c-1.115-.736-2.152-1.494-3.222-2.23a191.463 191.463 0 01-6.344-4.638 133.25 133.25 0 017.102 3.344z"
        fill="url(#prefix__paint37_linear)"
      />
      <path
        d="M205.238 45.975a90.212 90.212 0 017.659 9.9c2.352 3.445 4.459 7.012 6.622 10.613a125.421 125.421 0 016.121 17.939 166.94 166.94 0 013.723 18.584v.067a244.66 244.66 0 01-.78 15.831c-.435 5.263-1.014 10.514-1.839 15.742a209.126 209.126 0 01-3.033 15.542 175.552 175.552 0 01-4.314 15.229c1.471-5.062 2.798-10.168 3.89-15.319 1.093-5.15 2.041-10.346 2.776-15.608.736-5.262 1.316-10.446 1.706-15.697.39-5.251.625-10.513.703-15.775v.066a167.995 167.995 0 00-3.613-18.506 140.23 140.23 0 00-5.886-17.916 207.567 207.567 0 00-6.422-10.714 97.125 97.125 0 00-7.313-9.978z"
        fill="#F9F5DE"
      />
      <path
        d="M209.117 61.483a308.405 308.405 0 013.59 13.01c1.115 4.37 2.152 8.74 3.089 13.144v.012c.2 4.905.345 9.814.434 14.727.09 4.913.093 9.837.011 14.772a124.043 124.043 0 01-1.493 14.672c-.759 4.86-1.795 9.665-2.999 14.426a222.498 222.498 0 002.475-14.493c.657-4.85 1.114-9.744 1.226-14.627.111-4.883 0-9.8 0-14.694 0-4.895-.156-9.8-.323-14.705v.066c-.903-4.403-1.806-8.807-2.81-13.177-1.003-4.37-2.118-8.763-3.2-13.133z"
        fill="#F9F5DE"
      />
      <path
        d="M192.595 37l9.867 6.823h.055v.078c1.628 5.775 3.345 11.528 4.895 17.314 1.048 6.8 2.14 13.59 3.144 20.391v.1l-1.327 15.608-1.461 15.609-2.675 10.635 2.319-10.613 1.115-15.608 1.226-15.608v.1c-1.115-6.79-2.04-13.601-3.055-20.402-1.561-5.797-3.032-11.606-4.56-17.414l.078.122L192.595 37z"
        fill="#F9F5DE"
      />
      <path
        ref={glow}
        style={{
          mixBlendMode: 'screen',
        }}
        opacity={0.75}
        d="M172.985 209.136L275.72 318.972H.872L96.27 209.136h76.714z"
        fill="url(#prefix__paint38_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={132.303}
          y1={-13.637}
          x2={132.76}
          y2={205.423}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D137D" />
          <stop offset={0.09} stopColor="#972070" />
          <stop offset={0.35} stopColor="#DD444B" />
          <stop offset={0.48} stopColor="#F9523C" />
          <stop offset={1} stopColor="#F9E79A" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={137.688}
          y1={12.195}
          x2={137.387}
          y2={128.475}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.05} stopColor="#740D74" />
          <stop offset={0.09} stopColor="#7E126E" />
          <stop offset={0.28} stopColor="#A52457" />
          <stop offset={0.46} stopColor="#C43245" />
          <stop offset={0.64} stopColor="#DA3D38" />
          <stop offset={0.81} stopColor="#E74331" />
          <stop offset={0.97} stopColor="#EC452E" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={133.552}
          y1={5.104}
          x2={134.009}
          y2={125.331}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.18} stopColor="#660F66" />
          <stop offset={0.33} stopColor="#801B5C" />
          <stop offset={0.76} stopColor="#C63D41" />
          <stop offset={0.97} stopColor="#E24A36" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={134.577}
          y1={213.783}
          x2={134.852}
          y2={186.695}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.12} stopColor="#F9523C" />
          <stop offset={1} stopColor="#F9E79A" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={97.932}
          y1={38.907}
          x2={87.352}
          y2={3.956}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B644A" />
          <stop offset={0.98} />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={63.828}
          y1={178.555}
          x2={50.628}
          y2={57.402}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.32} stopColor="#A75053" />
          <stop offset={0.65} stopColor="#584D69" />
          <stop offset={0.88} stopColor="#264C78" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={71.153}
          y1={170.004}
          x2={53.85}
          y2={88.63}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={2443.97}
          y1={1538.16}
          x2={1713.29}
          y2={1589.72}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear"
          x1={1139}
          y1={1373.18}
          x2={944.262}
          y2={1426.89}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B644A" />
          <stop offset={0.98} />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear"
          x1={1103.47}
          y1={921.921}
          x2={906.597}
          y2={941.969}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear"
          x1={2024.3}
          y1={2555.22}
          x2={1298.26}
          y2={2337.59}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear"
          x1={6945.06}
          y1={18993.9}
          x2={3907.65}
          y2={19111.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.31} stopColor="#B6504F" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear"
          x1={-6676.26}
          y1={18994.3}
          x2={-3638.86}
          y2={19112.1}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.31} stopColor="#B6504F" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear"
          x1={2000.3}
          y1={12081.5}
          x2={904.381}
          y2={12261.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B644A" />
          <stop offset={0.98} />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear"
          x1={1048.48}
          y1={3591.76}
          x2={568.61}
          y2={3749.64}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B644A" />
          <stop offset={0.98} />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear"
          x1={2429.12}
          y1={5072.15}
          x2={1630.73}
          y2={5087.71}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear"
          x1={84.163}
          y1={102.733}
          x2={52.891}
          y2={113.056}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.16} stopColor="#BD504D" />
          <stop offset={0.31} stopColor="#8A4F5B" />
          <stop offset={0.47} stopColor="#5F4D67" />
          <stop offset={0.62} stopColor="#3E4C71" />
          <stop offset={0.76} stopColor="#264C78" />
          <stop offset={0.89} stopColor="#184B7C" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint18_linear"
          x1={82.201}
          y1={58.986}
          x2={62.903}
          y2={64.225}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.3} stopColor="#AF5051" />
          <stop offset={0.79} stopColor="#404C70" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint19_linear"
          x1={1298.11}
          y1={747.266}
          x2={1598.4}
          y2={795.58}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint20_linear"
          x1={2956.21}
          y1={4634.99}
          x2={2243.22}
          y2={4690.07}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint21_linear"
          x1={36.915}
          y1={107.014}
          x2={107.286}
          y2={97.905}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.15} stopColor="#DD5144" />
          <stop offset={0.47} stopColor="#964F58" />
          <stop offset={0.93} stopColor="#254C78" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint23_linear"
          x1={-9578.57}
          y1={1638.47}
          x2={-9322.48}
          y2={370.608}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B644A" />
          <stop offset={0.98} />
        </linearGradient>
        <linearGradient
          id="prefix__paint24_linear"
          x1={-4287.91}
          y1={20454.4}
          x2={457.101}
          y2={9029.34}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.32} stopColor="#A75053" />
          <stop offset={0.65} stopColor="#584D69" />
          <stop offset={0.88} stopColor="#264C78" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint25_linear"
          x1={-3753.46}
          y1={14592.7}
          x2={-458.675}
          y2={10027.9}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint26_linear"
          x1={-3494.93}
          y1={1538.17}
          x2={-2763.18}
          y2={1589.89}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint27_linear"
          x1={-1438.83}
          y1={1373.18}
          x2={-1245.25}
          y2={1426.21}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B644A" />
          <stop offset={0.98} />
        </linearGradient>
        <linearGradient
          id="prefix__paint28_linear"
          x1={-1411.38}
          y1={921.921}
          x2={-1214.5}
          y2={941.969}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint29_linear"
          x1={-2727.48}
          y1={2555.22}
          x2={-2002.81}
          y2={2338.38}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint30_linear"
          x1={-2693.34}
          y1={12078.2}
          x2={-1597.43}
          y2={12258.1}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B644A" />
          <stop offset={0.98} />
        </linearGradient>
        <linearGradient
          id="prefix__paint31_linear"
          x1={-1342.93}
          y1={3591.76}
          x2={-863.063}
          y2={3749.63}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B644A" />
          <stop offset={0.98} />
        </linearGradient>
        <linearGradient
          id="prefix__paint32_linear"
          x1={-3444.16}
          y1={5072.15}
          x2={-2644.31}
          y2={5087.76}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint33_linear"
          x1={-4723.38}
          y1={4988.2}
          x2={-3794.31}
          y2={5182.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.16} stopColor="#BD504D" />
          <stop offset={0.31} stopColor="#8A4F5B" />
          <stop offset={0.47} stopColor="#5F4D67" />
          <stop offset={0.62} stopColor="#3E4C71" />
          <stop offset={0.76} stopColor="#264C78" />
          <stop offset={0.89} stopColor="#184B7C" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint34_linear"
          x1={-975.965}
          y1={525.506}
          x2={-849.356}
          y2={557.484}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.3} stopColor="#AF5051" />
          <stop offset={0.79} stopColor="#404C70" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint35_linear"
          x1={-1894.74}
          y1={750.772}
          x2={-2196.88}
          y2={799.443}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint36_linear"
          x1={-4224.52}
          y1={4634.99}
          x2={-3511.83}
          y2={4690.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.21} stopColor="#B7504F" />
          <stop offset={0.41} stopColor="#7C4E5F" />
          <stop offset={0.6} stopColor="#4F4D6C" />
          <stop offset={0.77} stopColor="#2E4C75" />
          <stop offset={0.91} stopColor="#1A4B7B" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint37_linear"
          x1={-4377.93}
          y1={13774.1}
          x2={-6914.83}
          y2={13676.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.15} stopColor="#DD5144" />
          <stop offset={0.47} stopColor="#964F58" />
          <stop offset={0.93} stopColor="#254C78" />
          <stop offset={1} stopColor="#134B7D" />
        </linearGradient>
        <linearGradient
          id="prefix__paint38_linear"
          x1={122.504}
          y1={191.51}
          x2={136.629}
          y2={305.516}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC5A1" />
          <stop offset={0.17} stopColor="#C6997D" stopOpacity={0.78} />
          <stop offset={0.4} stopColor="#806351" stopOpacity={0.5} />
          <stop offset={0.6} stopColor="#49392E" stopOpacity={0.29} />
          <stop offset={0.78} stopColor="#211A15" stopOpacity={0.13} />
          <stop offset={0.92} stopColor="#090706" stopOpacity={0.04} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <radialGradient
          id="prefix__paint4_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(4601.29 0 0 5323.02 8079.6 4699.25)"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.31} stopColor="#B6504F" />
          <stop offset={1} stopColor="#134B7D" />
        </radialGradient>
        <radialGradient
          id="prefix__paint22_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-4604.18 0 0 -5323.02 -11660.2 4699.25)"
        >
          <stop stopColor="#F9523C" />
          <stop offset={0.31} stopColor="#B6504F" />
          <stop offset={1} stopColor="#134B7D" />
        </radialGradient>
      </defs>
    </svg>
  );
};

Hell.displayName = 'Hell';

export default memo(forwardRef(Hell));
