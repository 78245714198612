import React, { forwardRef, memo, Ref, SVGProps } from 'react';

const ShieldLock = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => (
  <svg
    width="189"
    height="200"
    viewBox="0 0 189 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M109.322 8.94859C92.1077 24.4082 77.2547 23.5957 67.9595 16.4346C58.675 34.321 43.8113 52.2832 26.6074 56.7033L16.7813 83.2458C16.7813 83.2458 22.0465 89.3126 22.0465 103.83C22.0465 110.373 21.0498 116.126 19.6089 121.846C17.8105 129.007 15.503 136.428 15.503 148.8C15.503 157.196 16.608 165.354 20.8548 172.775C23.3032 177.065 26.8458 180.705 31.6126 183.555C28.4383 181.724 19.0889 176.328 16.673 174.931C11.6137 172.017 7.89773 168.279 5.35182 163.826C1.10503 156.384 0 148.237 0 139.841C0 127.469 2.30757 120.058 4.10595 112.876C5.53599 107.166 6.54352 101.414 6.54352 94.8703C6.54352 80.3532 1.27837 74.2863 1.27837 74.2863L11.0937 47.7439C28.3083 43.3346 43.1721 25.3724 52.4457 7.48605C61.741 14.6363 76.6047 15.4488 93.8086 0L109.322 8.94859Z"
        fill="#257D69"
      />
      <path
        opacity="0.8"
        d="M57.8951 10.6386L52.4458 7.49683C54.0925 8.75353 55.9234 9.81523 57.8951 10.6386Z"
        fill="#257D69"
      />
      <path
        opacity="0.85"
        d="M93.8194 0C79.649 12.7187 67.082 14.4196 57.895 10.6386L67.9487 16.4455C77.2439 23.5957 92.1077 24.4082 109.312 8.95943L93.8194 0Z"
        fill="#257D69"
      />
      <path
        opacity="0.65"
        d="M52.4458 7.48608C43.1722 25.3724 28.3084 43.3346 11.0938 47.7548L26.5967 56.7034C43.8114 52.2832 58.6643 34.321 67.9488 16.4347L52.4458 7.48608Z"
        fill="#257D69"
      />
      <path
        opacity="0.55"
        d="M11.0936 47.7546L1.27832 74.2971L16.7704 83.2457L26.5966 56.7032L11.0936 47.7546Z"
        fill="#257D69"
      />
      <path
        opacity="0.4"
        d="M20.8548 172.764C16.608 165.343 15.503 157.185 15.503 148.789C15.503 136.417 17.8105 129.007 19.6089 121.835C21.0389 116.126 22.0465 110.373 22.0465 103.819C22.0465 89.3018 16.7813 83.235 16.7813 83.235L1.2892 74.2864C1.2892 74.2864 6.55436 80.3532 6.55436 94.8703C6.55436 101.414 5.54683 107.167 4.11679 112.876C2.30757 120.059 0 127.469 0 139.841C0 148.237 1.10503 156.384 5.35182 163.816C7.89773 168.268 11.6245 172.006 16.673 174.92C19.0889 176.318 28.4383 181.713 31.6126 183.544C26.8458 180.695 23.3032 177.065 20.8548 172.764Z"
        fill="#257D69"
      />
      <path
        opacity="0.05"
        d="M20.8548 172.764C16.608 165.343 15.503 157.185 15.503 148.789C15.503 136.417 17.8105 129.007 19.6089 121.835C21.0389 116.126 22.0465 110.373 22.0465 103.819C22.0465 89.3018 16.7813 83.235 16.7813 83.235L1.2892 74.2864C1.2892 74.2864 6.55436 80.3532 6.55436 94.8703C6.55436 101.414 5.54683 107.167 4.11679 112.876C2.30757 120.059 0 127.469 0 139.841C0 148.237 1.10503 156.384 5.35182 163.816C7.89773 168.268 11.6245 172.006 16.673 174.92C19.0889 176.318 28.4383 181.713 31.6126 183.544C26.8458 180.695 23.3032 177.065 20.8548 172.764Z"
        fill="#257D69"
      />
      <path
        d="M67.9594 190.943C42.2512 191.225 27.5283 184.454 20.8548 172.764C16.6188 165.343 15.5029 157.185 15.5029 148.789C15.5029 136.417 17.7997 129.007 19.598 121.835C21.0281 116.126 22.0356 110.373 22.0356 103.83C22.0356 89.3125 16.7705 83.2457 16.7705 83.2457L26.5966 56.7032C43.8004 52.2831 58.6642 34.3209 67.9594 16.4345C77.2439 23.5956 92.1077 24.3973 109.322 8.94849L119.138 24.1481C119.138 24.1481 113.872 36.2926 113.872 50.8205C113.872 57.3641 114.88 61.9467 116.31 66.0093C118.108 71.1011 120.405 75.8571 120.405 88.2291C120.405 96.6252 119.3 106.05 115.053 118.379C108.38 137.771 93.6569 161.551 67.9594 190.943Z"
        fill="#3ED598"
      />
      <path
        opacity="0.75"
        d="M67.9594 190.943C42.2512 191.225 27.5283 184.454 20.8548 172.764C16.6188 165.343 15.5029 157.185 15.5029 148.789C15.5029 136.417 17.7997 129.007 19.598 121.835C21.0281 116.126 22.0356 110.373 22.0356 103.83C22.0356 89.3125 16.7705 83.2457 16.7705 83.2457L26.5966 56.7032C43.8004 52.2831 58.6642 34.3209 67.9594 16.4345C77.2439 23.5956 92.1077 24.3973 109.322 8.94849L119.138 24.1481C119.138 24.1481 113.872 36.2926 113.872 50.8205C113.872 57.3641 114.88 61.9467 116.31 66.0093C118.108 71.1011 120.405 75.8571 120.405 88.2291C120.405 96.6252 119.3 106.05 115.053 118.379C108.38 137.771 93.6569 161.551 67.9594 190.943Z"
        fill="#3ED598"
      />
      <path
        opacity="0.75"
        d="M64.9151 184.269C45.0029 184.031 32.1325 179.058 26.6507 169.46C23.5631 164.054 22.1873 157.673 22.1873 148.8C22.1873 138.638 23.8556 132.16 25.4699 125.898C25.6757 125.074 25.8924 124.273 26.0874 123.471C27.9074 116.191 28.7308 110.135 28.7308 103.84C28.7308 93.2667 26.1957 86.3332 24.1806 82.4981L31.7209 62.1308C50.1381 55.7281 63.4526 37.5384 70.5053 25.6106C74.2538 27.2139 78.3164 28.0373 82.5198 28.0373C90.8401 28.0373 99.3011 24.9389 107.741 18.8179L111.706 24.9497C109.983 29.9224 107.199 39.7051 107.199 50.8205C107.199 57.3315 108.066 62.6941 110.016 68.2301C110.221 68.8152 110.438 69.4002 110.655 69.9852C112.237 74.3186 113.742 78.4029 113.742 88.2291C113.742 97.5568 112.204 106.191 108.759 116.202C102.172 135.29 87.4275 158.182 64.9151 184.269Z"
        fill="#3ED598"
      />
      <path
        opacity="0.4"
        d="M64.9151 184.269C45.0029 184.031 32.1325 179.058 26.6507 169.46C23.5631 164.054 22.1873 157.673 22.1873 148.8C22.1873 138.638 23.8556 132.16 25.4699 125.898C25.6757 125.074 25.8924 124.273 26.0874 123.471C27.9074 116.191 28.7308 110.135 28.7308 103.84C28.7308 93.2667 26.1957 86.3332 24.1806 82.4981L31.7209 62.1308C50.1381 55.7281 63.4526 37.5384 70.5053 25.6106C74.2538 27.2139 78.3164 28.0373 82.5198 28.0373C90.8401 28.0373 99.3011 24.9389 107.741 18.8179L111.706 24.9497C109.983 29.9224 107.199 39.7051 107.199 50.8205C107.199 57.3315 108.066 62.6941 110.016 68.2301C110.221 68.8152 110.438 69.4002 110.655 69.9852C112.237 74.3186 113.742 78.4029 113.742 88.2291C113.742 97.5568 112.204 106.191 108.759 116.202C102.172 135.29 87.4275 158.182 64.9151 184.269Z"
        fill="#3ED598"
      />
      <path
        opacity="0.15"
        d="M64.9151 184.269C45.0029 184.031 32.1325 179.058 26.6507 169.46C23.5631 164.054 22.1873 157.673 22.1873 148.8C22.1873 138.638 23.8556 132.16 25.4699 125.898C25.6757 125.074 25.8924 124.273 26.0874 123.471C27.9074 116.191 28.7308 110.135 28.7308 103.84C28.7308 93.2667 26.1957 86.3332 24.1806 82.4981L31.7209 62.1308C50.1381 55.7281 63.4526 37.5384 70.5053 25.6106C74.2538 27.2139 78.3164 28.0373 82.5198 28.0373C90.8401 28.0373 99.3011 24.9389 107.741 18.8179L111.706 24.9497C109.983 29.9224 107.199 39.7051 107.199 50.8205C107.199 57.3315 108.066 62.6941 110.016 68.2301C110.221 68.8152 110.438 69.4002 110.655 69.9852C112.237 74.3186 113.742 78.4029 113.742 88.2291C113.742 97.5568 112.204 106.191 108.759 116.202C102.172 135.29 87.4275 158.182 64.9151 184.269Z"
        fill="#3ED598"
      />
      <path
        opacity="0.8"
        d="M31.2117 174.974C37.9935 180.954 49.2714 184.085 64.9152 184.269L54.9265 178.582C45.9996 178.528 38.0477 177.368 31.2117 174.974Z"
        fill="#3ED598"
      />
      <path
        opacity="0.15"
        d="M106.506 21.9488L111.695 24.9497L107.741 18.8179L105.487 20.3671L106.506 21.9488Z"
        fill="#3ED598"
      />
      <path
        d="M104.805 65.2295C102.855 59.6935 101.988 54.3308 101.988 47.8198C101.988 36.7045 104.783 26.9217 106.495 21.9491L105.476 20.3782C97.7846 25.47 90.0927 28.0484 82.5091 28.0484C78.3057 28.0484 74.243 27.225 70.4946 25.6217C63.4419 37.5495 50.1273 55.7392 31.7101 62.1419L24.1699 82.5092C26.185 86.3443 28.72 93.267 28.72 103.851C28.72 110.135 27.9075 116.191 26.0766 123.482C25.8708 124.284 25.6649 125.096 25.4591 125.909C23.8449 132.171 22.1765 138.649 22.1765 148.811C22.1765 157.695 23.5524 164.065 26.64 169.471C28.2 172.201 30.3776 174.541 33.1185 176.524C39.7704 179.557 48.6431 181.16 59.7043 181.29C82.2274 155.203 96.972 132.311 103.537 113.223C106.982 103.212 108.521 94.5778 108.521 85.2501C108.521 75.424 107.026 71.3397 105.433 67.0062C105.227 66.3995 105.021 65.8145 104.805 65.2295Z"
        fill="#22343C"
      />
      <path
        d="M31.7101 62.1311L24.1699 82.4983C26.185 86.3334 28.72 93.2561 28.72 103.841C28.72 110.124 27.9075 116.18 26.0766 123.471C25.8708 124.273 25.6649 125.085 25.4591 125.898C23.8449 132.16 22.1765 138.638 22.1765 148.8C22.1765 157.684 23.5524 164.054 26.64 169.46C28.2 172.19 30.3776 174.53 33.1185 176.513C39.7704 179.546 48.6431 181.15 59.7043 181.28V106.213L70.4837 102.952V25.6108C67.472 30.7568 63.9186 35.9028 59.7043 41.038C52.4999 49.7265 43.0855 58.1768 31.7101 62.1311Z"
        fill="#30444E"
      />
      <path
        d="M85.7374 78.4682C85.6291 78.2624 85.4991 78.0674 85.3257 77.8832C85.3041 77.8616 85.2932 77.8399 85.2716 77.8182C84.979 77.504 84.6107 77.2115 84.1557 76.9407L80.5373 74.8498C79.9197 74.4923 79.2155 74.2323 78.4572 74.0589C78.0238 73.9614 77.5797 73.8964 77.1355 73.8531V63.1711C77.1355 57.8085 75.1096 53.5725 71.5778 51.5358C68.2086 49.5857 64.0376 49.8566 59.845 52.2725C52.1314 56.7251 46.0971 67.5913 46.0971 77.0057V89.6161L38.9144 93.7654C38.6869 93.8954 38.4919 94.0362 38.3077 94.177C38.2427 94.2312 38.1777 94.2745 38.1127 94.3287C38.1019 94.3395 38.0802 94.3612 38.0694 94.372C37.4085 94.9679 37.0727 95.6504 37.0835 96.3329V139.592C37.0835 139.776 37.116 139.96 37.1593 140.155C37.3218 140.783 37.7769 141.39 38.5135 141.91C38.6436 141.997 38.7736 142.083 38.9144 142.17L40.6694 143.188L42.5328 144.261C43.2045 144.651 44.0062 144.922 44.8512 145.095C47.0613 145.55 49.6289 145.279 51.4056 144.261L84.1557 125.356C85.3799 124.652 85.9974 123.72 85.9974 122.8V79.5083C85.9974 79.1616 85.9107 78.8149 85.7374 78.4682ZM53.1823 77.0057C53.1823 70.1155 57.8624 61.6002 63.3984 58.4043C65.2835 57.3101 66.9844 57.0393 68.0569 57.6676C69.3136 58.3935 70.072 60.4519 70.072 63.1711V75.7815L60.755 81.155L53.1823 85.5209V77.0057Z"
        fill="#263238"
      />
      <path
        d="M89.8109 81.4147C89.8109 80.483 89.1934 79.5621 87.9692 78.8579L84.3507 76.767C81.9023 75.3478 77.9264 75.3478 75.478 76.767L42.7387 95.6718C41.5145 96.3759 40.897 97.3076 40.897 98.2393V141.498C40.897 142.43 41.5037 143.361 42.7387 144.066L46.3463 146.157C48.7947 147.576 52.7707 147.576 55.2191 146.157L87.9692 127.252C89.1934 126.548 89.8109 125.616 89.8109 124.695V81.4147Z"
        fill="#3ED598"
      />
      <path
        opacity="0.4"
        d="M46.3465 102.887L42.7389 100.796C40.2905 99.3769 40.2905 97.091 42.7389 95.6718L75.489 76.767C77.9374 75.3478 81.9134 75.3478 84.3618 76.767L87.9802 78.8579C90.4286 80.2771 90.4286 82.563 87.9802 83.9822L55.2193 102.887C52.7708 104.295 48.7949 104.295 46.3465 102.887Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M50.7882 103.938V147.208C49.174 147.208 47.5706 146.861 46.3464 146.157L42.728 144.066C41.5038 143.362 40.8863 142.43 40.8971 141.498V98.2395C40.8971 99.1712 41.5038 100.092 42.728 100.796L46.3464 102.887C47.5706 103.591 49.1849 103.938 50.7882 103.938Z"
        fill="black"
      />
      <path
        d="M74.6222 125.344L66.8003 129.861L68.0136 115.713C67.0819 115.214 66.4861 114.001 66.4861 112.257C66.4861 109.137 68.382 105.518 70.7112 104.175C73.0513 102.831 74.9363 104.261 74.9363 107.371C74.9363 109.115 74.3405 111.022 73.4088 112.592L74.6222 125.344Z"
        fill="#455A64"
      />
      <path d="M74.6222 125.345L67.537 121.25L66.8003 129.863L74.6222 125.345Z" fill="#37474F" />
      <path
        d="M70.7111 104.176C69.1836 105.054 67.851 106.917 67.1035 108.965L73.4087 112.605C74.3404 111.034 74.9362 109.127 74.9362 107.383C74.9471 104.252 73.0512 102.822 70.7111 104.176Z"
        fill="#37474F"
      />
      <path
        d="M80.96 82.379V65.0776C80.96 59.715 78.9341 55.479 75.4024 53.4423C72.0331 51.4922 67.8622 51.7631 63.6695 54.179C55.956 58.6316 49.9216 69.4978 49.9216 78.9122V96.3002C49.9216 96.8202 50.2683 97.3402 50.9617 97.7411C52.3375 98.5428 54.5801 98.5428 55.9668 97.7411C56.6602 97.3402 57.0068 96.8202 57.0068 96.3002V78.9122C57.0068 72.022 61.687 63.5067 67.223 60.3108C69.108 59.2166 70.8089 58.9458 71.8814 59.5741C73.149 60.3 73.8965 62.3584 73.8965 65.0776V82.379V82.3898C73.9073 82.9098 74.2432 83.4298 74.9365 83.8198C76.3124 84.6215 78.555 84.6215 79.9417 83.8198C80.6134 83.419 80.96 82.899 80.96 82.379Z"
        fill="#E0E0E0"
      />
      <path
        d="M123.059 158.41L127.826 155.658L129.711 153.372L124.944 156.124L123.059 158.41Z"
        fill="#257D69"
      />
      <path
        opacity="0.4"
        d="M123.059 158.41L127.826 155.658L129.711 153.372L124.944 156.124L123.059 158.41Z"
        fill="#257D69"
      />
      <path
        d="M136.038 165.907L140.805 163.155L144.781 159.623L140.014 162.375L136.038 165.907Z"
        fill="#257D69"
      />
      <path
        opacity="0.4"
        d="M136.038 165.907L140.805 163.155L144.781 159.623L140.014 162.375L136.038 165.907Z"
        fill="#257D69"
      />
      <path
        d="M176.567 175.418C177.607 176.751 178.376 178.701 178.538 180.608C177.488 179.275 176.729 177.314 176.567 175.418ZM187.65 175.559C186.046 164.639 180.174 153.838 173.187 149.797C169.828 147.858 166.73 147.782 164.238 149.244C163.512 149.667 160.815 151.227 159.742 151.833C159.655 151.877 159.569 151.931 159.493 151.985C159.298 152.093 159.103 152.223 158.919 152.353C158.561 152.603 158.215 152.884 157.89 153.209C157.846 153.253 157.792 153.307 157.738 153.35C157.597 153.491 157.456 153.643 157.326 153.805C157.164 153.989 157.001 154.184 156.849 154.39C156.535 154.802 156.243 155.246 155.972 155.734C155.842 155.972 155.701 156.221 155.582 156.481L116.375 133.85L111.608 136.591V139.624L112.02 139.862L112.399 140.079V140.718L111.619 141.173V142.939L117.372 155.127L123.07 158.42L124.966 156.124L129.993 159.125L132.875 164.065L136.049 165.896L140.036 162.364L144.922 165.191L146.568 171.962L154.759 176.686C155.17 178.419 155.701 180.12 156.308 181.778C156.362 181.951 156.427 182.124 156.492 182.308C159.211 189.47 163.545 195.591 168.431 198.418C169.059 198.776 169.666 199.079 170.272 199.306C170.619 199.447 170.966 199.556 171.302 199.653C171.605 199.74 171.908 199.805 172.222 199.859C173.23 200.043 174.205 200.032 175.126 199.848C175.256 199.816 175.397 199.783 175.527 199.751C175.711 199.707 175.906 199.653 176.079 199.588C176.22 199.534 176.361 199.48 176.502 199.426C176.653 199.371 176.794 199.296 176.935 199.22C177.076 199.155 177.206 199.09 177.347 199.003C177.412 198.96 177.488 198.927 177.553 198.884C177.585 198.873 177.618 198.851 177.65 198.83C178.733 198.201 181.42 196.652 182.146 196.241C185.764 194.139 188.115 188.83 188.115 181.323C188.083 179.427 187.931 177.488 187.65 175.559Z"
        fill="#3ED598"
      />
      <path
        d="M175.169 184.627C173.284 183.544 171.768 180.315 171.768 177.423C171.768 174.53 173.295 173.057 175.169 174.151C177.054 175.234 178.571 178.463 178.571 181.355C178.571 184.248 177.044 185.71 175.169 184.627ZM182.883 178.3C181.279 167.391 175.408 156.579 168.42 152.549C165.181 150.674 162.18 150.534 159.742 151.834C159.656 151.877 159.569 151.931 159.493 151.985C159.298 152.094 159.103 152.224 158.919 152.354C158.561 152.603 158.215 152.885 157.89 153.21C157.846 153.253 157.792 153.307 157.738 153.35C157.597 153.491 157.456 153.643 157.326 153.805C157.164 153.99 157.001 154.185 156.85 154.39C156.535 154.802 156.243 155.246 155.972 155.734C155.842 155.972 155.701 156.221 155.582 156.481C154.932 157.814 154.412 159.363 154.076 161.118L111.619 136.602V139.635L112.031 139.873L112.41 140.09V140.729L111.63 141.184V142.95L117.383 155.138L123.081 158.431L124.977 156.135L130.004 159.136L132.886 164.076L136.06 165.907L140.047 162.375L144.933 165.202L146.579 171.973L154.77 176.697C155.181 178.43 155.712 180.131 156.319 181.789C156.373 181.962 156.438 182.135 156.503 182.32C159.222 189.481 163.556 195.602 168.442 198.429C169.07 198.787 169.677 199.09 170.283 199.318C170.63 199.458 170.977 199.567 171.313 199.664C171.616 199.751 171.919 199.816 172.233 199.87C173.241 200.054 174.216 200.043 175.137 199.859C175.267 199.827 175.408 199.794 175.538 199.762C175.722 199.718 175.917 199.664 176.09 199.599C176.231 199.545 176.372 199.491 176.513 199.437C176.664 199.383 176.805 199.307 176.946 199.231C177.087 199.166 177.217 199.101 177.358 199.014C177.423 198.971 177.499 198.938 177.564 198.895C177.596 198.884 177.629 198.863 177.661 198.841C181.139 196.652 183.36 191.42 183.36 184.085C183.316 182.179 183.165 180.24 182.883 178.3Z"
        fill="#3ED598"
      />
      <path
        opacity="0.4"
        d="M178.571 181.345C178.571 184.237 177.043 185.71 175.169 184.627C173.284 183.544 171.757 180.315 171.757 177.423C171.757 174.53 173.284 173.057 175.169 174.14C175.668 174.433 176.144 174.866 176.567 175.418C176.729 177.314 177.498 179.275 178.538 180.608C178.56 180.857 178.571 181.106 178.571 181.345Z"
        fill="black"
      />
      <path
        opacity="0.4"
        d="M188.083 181.344C188.083 188.852 185.732 194.171 182.114 196.262C181.388 196.674 178.701 198.223 177.618 198.852C181.095 196.663 183.316 191.431 183.316 184.096C183.316 182.189 183.165 180.25 182.883 178.311C181.279 167.402 175.408 156.59 168.42 152.559C165.181 150.685 162.18 150.544 159.742 151.844C160.804 151.238 163.501 149.678 164.238 149.255C166.73 147.803 169.828 147.868 173.187 149.808C180.185 153.849 186.046 164.65 187.65 175.57C187.931 177.488 188.083 179.427 188.083 181.344Z"
        fill="white"
      />
      <path
        d="M183.316 184.096C183.316 191.431 181.084 196.663 177.618 198.852C178.701 198.223 181.388 196.674 182.114 196.262C185.732 194.161 188.083 188.852 188.083 181.344C188.083 181.063 188.072 180.792 188.072 180.51C186.436 181.453 184.779 182.406 183.305 183.262C183.305 183.533 183.316 183.815 183.316 184.096Z"
        fill="#3ED598"
      />
      <path
        opacity="0.1"
        d="M183.316 184.096C183.316 191.431 181.084 196.663 177.618 198.852C178.701 198.223 181.388 196.674 182.114 196.262C185.732 194.161 188.083 188.852 188.083 181.344C188.083 181.063 188.072 180.792 188.072 180.51C186.436 181.453 184.779 182.406 183.305 183.262C183.305 183.533 183.316 183.815 183.316 184.096Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M154.228 164.227V165.777L111.608 141.163L112.388 140.718V140.068L154.228 164.227Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M154.228 164.867V165.777L111.608 141.163L112.388 140.718V140.708L154.228 164.867Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M174.596 195.626C180.539 193.802 182.59 183.309 179.177 172.189C175.765 161.07 168.18 153.534 162.237 155.358C156.294 157.182 154.243 167.675 157.656 178.794C161.068 189.914 168.653 197.45 174.596 195.626Z"
        stroke="black"
        strokeWidth="0.7315"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.5"
        d="M155.571 156.47C154.921 157.803 154.401 159.352 154.065 161.107L111.608 136.591L116.375 133.85L155.571 156.47Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="188.083" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Composed = memo(forwardRef(ShieldLock));
Composed.displayName = 'ShieldLock';

export default Composed;
