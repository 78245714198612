import React, { forwardRef, memo, Ref, SVGProps } from 'react';

const BritishFlagIcon = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <path
      d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
      fill="#F0F0F0"
    />
    <path
      d="M3.72097 7.04102C2.30705 8.8806 1.24091 11.0009 0.620117 13.3042H9.98419L3.72097 7.04102Z"
      fill="#0052B4"
    />
    <path
      d="M35.3801 13.3042C34.7593 11.0009 33.693 8.8806 32.2792 7.04102L26.0161 13.3042H35.3801Z"
      fill="#0052B4"
    />
    <path
      d="M0.620117 22.6956C1.24098 24.9989 2.30712 27.1191 3.72097 28.9586L9.98398 22.6956H0.620117Z"
      fill="#0052B4"
    />
    <path
      d="M28.9585 3.72079C27.1189 2.30688 24.9987 1.24073 22.6953 0.619873V9.98388L28.9585 3.72079Z"
      fill="#0052B4"
    />
    <path
      d="M7.04102 32.2786C8.8806 33.6926 11.0009 34.7587 13.3042 35.3796V26.0156L7.04102 32.2786Z"
      fill="#0052B4"
    />
    <path
      d="M13.3041 0.619873C11.0008 1.24073 8.88053 2.30688 7.04102 3.72072L13.3041 9.98381V0.619873Z"
      fill="#0052B4"
    />
    <path
      d="M22.6958 35.3796C24.9991 34.7587 27.1194 33.6926 28.9589 32.2787L22.6958 26.0156V35.3796Z"
      fill="#0052B4"
    />
    <path
      d="M26.0161 22.6956L32.2792 28.9587C33.693 27.1192 34.7593 24.9989 35.3801 22.6956H26.0161Z"
      fill="#0052B4"
    />
    <path
      d="M35.8476 15.6522H20.3479H20.3479V0.152367C19.5793 0.0523125 18.7957 0 18 0C17.2041 0 16.4207 0.0523125 15.6522 0.152367V15.6521V15.6521H0.152367C0.0523125 16.4207 0 17.2043 0 18C0 18.7959 0.0523125 19.5793 0.152367 20.3478H15.6521H15.6521V35.8476C16.4207 35.9477 17.2041 36 18 36C18.7957 36 19.5793 35.9478 20.3478 35.8476V20.3479V20.3479H35.8476C35.9477 19.5793 36 18.7959 36 18C36 17.2043 35.9477 16.4207 35.8476 15.6522Z"
      fill="#D80027"
    />
    <path
      d="M22.6953 22.6959L30.7275 30.7281C31.097 30.3588 31.4494 29.9727 31.7856 29.5725L24.9089 22.6958H22.6953V22.6959Z"
      fill="#D80027"
    />
    <path
      d="M13.3043 22.6958H13.3041L5.27197 30.728C5.64125 31.0974 6.02734 31.4498 6.42756 31.786L13.3043 24.9092V22.6958Z"
      fill="#D80027"
    />
    <path
      d="M13.3041 13.3044V13.3043L5.27193 5.27197C4.90251 5.64125 4.5501 6.02734 4.21387 6.42756L11.0906 13.3043H13.3041V13.3044Z"
      fill="#D80027"
    />
    <path
      d="M22.6953 13.3045L30.7276 5.2721C30.3583 4.90268 29.9722 4.55028 29.572 4.21411L22.6953 11.0909V13.3045Z"
      fill="#D80027"
    />
  </svg>
);

const Composed = memo(forwardRef(BritishFlagIcon));
Composed.displayName = 'BritishFlagIcon';

export default Composed;
