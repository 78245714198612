export const SLIDER_HEIGHT = 300;
export const SLIDER_WIDTH = 100;
export const INITIAL_THUMB_POSITION = 0;
export const SLIDER_TARGET_SIZE = 80;
export const SLIDE_PROGRESS_MIN = 0;
export const SLIDE_PROGRESS_MAX = 100;
export const ARROW_UP_BORDER_SIZE = 5;
export const COMPLETED_TOGGLING_ANIMATION_DURATION = 500;
export const AFTER_SUCCESS_INFO_DISPLAY_DURATION = 400;
export const INFO_BOX_TRANSITION_DURATION = 200;
