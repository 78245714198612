import React, { forwardRef, memo, Ref, SVGProps } from 'react';

const PrivilegesGroupIcon = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => (
  <svg
    width="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.9683 1.2949C11.1392 0.465788 10.0146 0 8.8421 0C7.66957 0 6.54506 0.465788 5.71595 1.2949C4.88684 2.124 4.42105 3.24852 4.42105 4.42105C4.42105 5.59359 4.88684 6.7181 5.71595 7.54721C6.54506 8.37632 7.66957 8.8421 8.8421 8.8421C10.0146 8.8421 11.1392 8.37632 11.9683 7.54721C12.7974 6.7181 13.2632 5.59359 13.2632 4.42105C13.2632 3.24852 12.7974 2.124 11.9683 1.2949ZM15.456 12.5386C13.8366 11.6269 11.4741 11.0526 8.8421 11.0526C3.95684 11.0526 0 13.0311 0 15.4737V17.6842H13.6354C13.3967 17.1817 13.2632 16.6196 13.2632 16.0263C13.2632 14.4901 14.1586 13.1631 15.456 12.5386Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5885 13.2604C19.9802 13.6617 21 14.9664 21 16.5139C21 18.3811 19.5155 19.8947 17.6842 19.8947C15.8529 19.8947 14.3684 18.3811 14.3684 16.5139C14.3684 14.9664 15.3882 13.6617 16.7799 13.2604V7.06305C16.7799 6.26769 17.3725 5.61345 18.1321 5.53435C18.1705 5.52905 18.2096 5.52632 18.2494 5.52632H19.3044C19.783 5.52632 20.171 5.92193 20.171 6.40994C20.171 6.89795 19.783 7.29356 19.3044 7.29356H18.5885V9.13764H19.3044C19.783 9.13764 20.171 9.53325 20.171 10.0213C20.171 10.5093 19.783 10.9049 19.3044 10.9049H18.5885V13.2604ZM17.6842 18.1275C18.5582 18.1275 19.2667 17.4051 19.2667 16.5139C19.2667 15.6228 18.5582 14.9004 17.6842 14.9004C16.8102 14.9004 16.1017 15.6228 16.1017 16.5139C16.1017 17.4051 16.8102 18.1275 17.6842 18.1275Z"
      fill="currentColor"
    />
  </svg>
);

const Composed = memo(forwardRef(PrivilegesGroupIcon));
Composed.displayName = 'PrivilegesGroupIcon';

export default Composed;
