import React, { forwardRef, memo, Ref, SVGProps } from 'react';

// converet to lithuanian flag icon this svg
const LithuanianFlagIcon = (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <clipPath id="circle">
      <circle cx="18" cy="18" r="18" />
    </clipPath>
    <rect x="0" y="0" width="36" height="12" fill="#FDB813" clipPath="url(#circle)" />
    <rect x="0" y="12" width="36" height="12" fill="#006A4E" clipPath="url(#circle)" />
    <rect x="0" y="24" width="36" height="12" fill="#C1272D" clipPath="url(#circle)" />
  </svg>
);

const Composed = memo(forwardRef(LithuanianFlagIcon));
Composed.displayName = 'LithuanianFlagIcon';

export default Composed;
