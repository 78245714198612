import { TranslationStructure } from './en';

const lt: TranslationStructure = {
  translation: {
    baseApiFields: {
      id: 'Id',
      email: 'El. paštas',
      createdAt: 'Sukurta',
      updatedAt: 'Atnaujinta',
    },
    user: {
      name: 'Vartotojas',
      firstName: 'Vardas',
      lastName: 'Pavardė',
      password: 'Slaptažodis',
      role: 'Rolė',
    },
    history: {
      event: 'Įvykis',
      date: 'Data',
      data: 'Detalės',
      device: 'Įrenginys',
    },
    openToken: {
      id: 'Raktas',
      valid: 'galioja',
      expired: 'pasibaigęs',
      status: 'Būsena',
      guestEmail: 'Svečio el. paštas',
      expiryDate: 'Galioja iki',
      limit: 'Limitas',
      createDate: 'Sukurta',
    },
    invitation: {
      name: 'Pakvietimas',
      inviter: 'Pakvietėjas',
      sendDate: 'Išsiųsta',
    },
    actions: {
      back: 'Atgal',
      delete: 'Trinti',
      cancel: 'Atšaukti',
      save: 'Išsaugoti',
      regenerate: 'Perkurti',
    },
    menu: {
      history: 'Istorija',
      dashboard: 'Valdymas',
      settings: 'Nustatymai',
      admin: 'Administravimas',
      openTokens: 'Svečių raktai',
    },
    lists: {
      general: {
        noData: 'Nėra duomenų',
        removeError: 'Įvyko klaida trinant. Bandykite dar kartą.',
      },
      detailedList: {
        items: 'elementai',
        item: 'elementas',
        perPage: 'Eilučių puslapyje',
        ofTotal: 'iš',
        next: 'kitas',
        prev: 'ankstesnis',
      },
    },
    form: {
      errors: {
        onSubmitError: 'Ups! Įvyko klaida. Operacija nepavyko.',
      },
      success: {
        update: 'Atnaujinimas sėkmingas',
      },
      inputs: {
        defaultPlaceholderBase: 'Įveskite',
        confirmPassword: 'Patvirtinkite slaptažodį',
        repeatPassword: 'Pakartokite slaptažodį',
        newPassword: 'Naujas slaptažodis',
        repeatNewPassword: 'Pakartokite naują slaptažodį',
      },
      validation: {
        required: 'Privaloma',
        invalidEmail: 'Neteisingas el. pašto formatas',
        basePassword: 'Slaptažodis turi būti sudarytas iš bent',
        characters: 'simbolių',
        letter: 'raidės',
        lowercaseLetter: 'mažosios raidės',
        uppercaseLetter: 'didžiosios raidės',
        number: 'skaičiai',
        specialCharacter: 'specialūs simboliai',
        repeatPasswordError: 'Slaptažodžiai nesutampa',
      },
    },
    routes: {
      general: {
        sectionInConstruction: 'Ši sekcija dar nėra sukurta',
      },
      dashboard: {
        title: 'Valdymas',
        connecting: 'Jungiamasi...',
        sections: {
          camera: {
            title: 'Kamera',
            loadingPreview: 'Kraunama peržiūra...',
          },
          toggling: {
            swipeToToggle: 'Slinkite, kad atidarytumėte',
            toggleSuccess: 'Atidaryta sėkmingai',
            gateDisconnected: {
              title: 'Vartai nepasiekiami',
              description: 'Vartų atidarymo prietaisas nepasiekiamas. Bandykite dar kartą vėliau.',
              serverDisconnected: 'Serveris nepasiekiamas',
              deviceDisconnected: 'Vartų valdymo prietaisas nepasiekiamas',
            },
          },
        },
      },
      history: {
        title: 'Isorija',
        description:
          'Čia galite pamatyti visus savo įvykius, susijusius su vartų valdymu.<br />Jei norite pamatyti pilną valdymo istoriją, susisiekite su savo administratoriumi.',
        noData: 'Nėra įvykių',
        today: 'Šiandien',
        yesterday: 'Vakar',
        events: {
          personal: {
            open: 'vartų atidarymas',
            turnedOff: 'išjungta',
            turnedOn: 'įjungta',
          },
          impersonal: {
            open: 'Vartai atidaryti',
            turnedOff: 'Įrenginys išjungtas',
            turnedOn: 'Įrenginys įjungtas',
          },
          'open-token-generated': 'Svečio raktas sukurtas',
          'open-by-token': 'Svečias atidarė vartus',
        },
      },
      openTokens: {
        title: 'Svečių raktai',
        description: 'Čia galite peržiūrėti ir atšaukti savo svečių raktus.',
        noData: 'Nėra raktų',
        today: 'Šiandien',
        yesterday: 'Vakar',
        events: {
          personal: {
            open: 'vartų atidarymas',
            turnedOff: 'išjungta',
            turnedOn: 'įjungta',
          },
          impersonal: {
            open: 'Vartai atidaryti',
            turnedOff: 'Įrenginys išjungtas',
            turnedOn: 'Įrenginys įjungtas',
          },
        },
      },
      admin: {
        title: 'Administravimas',
        items: {
          device: 'Prietaisų nuostatos',
          users: 'Vartotojai',
          invitations: 'Pakvietimai',
          privileges: 'Teisės',
          statistics: 'Statistika',
        },
      },
      users: {
        isAdmin: 'Administratorius',
        userWithAdmin: 'Administratorius',
        userWithoutAdmin: 'Vartotojas',
      },
      settings: {
        title: 'Nustatymai',
        theme: {
          title: 'Tema',
          light: 'Šviesi',
          dark: 'Tamsi',
          system: 'Sistemos',
        },
        language: {
          title: 'Kalba',
          en: 'Anglų',
          lt: 'Lietuvių',
          pl: 'Lenkų',
        },
        logout: {
          title: 'Atsijungti',
          logout: 'Atsijungti',
          logoutFromAllDevices: 'Atsijungti iš visų prietaisų',
        },
        account: {
          title: 'Paskyra',
          basics: {
            title: 'Pagrindiniai',
          },
          actions: {
            title: 'Veiksmai',
            changePassword: 'Keisti slaptažodį',
            deleteAccount: 'Ištrinti paskyrą',
            passwordChanged: 'Slaptažodis sėkmingai pakeistas',
            deleteAccountInfo:
              'Ar tikrai norite ištrinti savo paskyrą? Šis veiksmas negrįžtamas ir visi susiję duomenys bus ištrinti.',
            accountDeleted: 'Paskyra sėkmingai ištrinta',
          },
          integrations: {
            title: 'Integracijos',
            extendedTitle: 'Integracijos su trečiųjų šalių paslaugomis',
            description:
              'Čia galite valdyti savo integracijas su trečiųjų šalių paslaugomis. Dėka šių integracijų galite pavyzdžiui, atidaryti vartus naudodami Google Assistant arba Amazon Alexa.',
            generateApiKey: 'Generuoti API raktą',
            regenerate: 'Perkurti',
            tokenCopied: 'Raktas nukopijuotas į iškarpinę',
            tokenDeleted: 'Raktas sėkmingai ištrintas',
            tokenManagement: 'Raktų valdymas',
            externalIntegrationsToken: 'Trečiųjų šalių integracijų raktas',
            integrationsTemplates: 'Integracijų šablonai',
            deleteToken: 'Ištrinti raktą',
            deleteTokenInfo:
              'Ar tikrai norite ištrinti savo raktą? Šis veiksmas negrįžtamas ir visi susiję duomenys bus ištrinti bei išjungtos integracijos.',
            templatesDescription:
              'Čia galite rasti integracijų šablonus, kurie padės jums greitai pradėti naudoti trečiųjų šalių paslaugas. Šie šablonai yra pilnai adaptuojami ir jiems reikalingi tik jūsų <strong>el. paštas</strong> ir <strong>API raktas</strong>.',
            regenerateTokenConfirmation: 'Perkurti raktą?',
            regenerateTokenConfirmationInfo:
              'Ar tikrai norite perkurti savo raktą? Šis veiksmas negrįžtamas ir visi susiję duomenys bus ištrinti bei išjungtos integracijos.',
            pickToStart: 'Pasirinkitę vieną iš nuorodų greitai pradžiai:',
            templatesNote:
              '<strong>Note:</strong> In order to add prepared integrations templates in most cases you will have to <strong>allow for untrusted templates/shortcuts</strong> in your device settings.',
            shortcutsTemplate: 'Apple Shortcuts šablonas',
            generateTokenToIntegrate:
              'Norėdami integruoti trečiųjų šalių paslaugas, turite sugeneruoti privatų raktą.',
            sharingTokenWarning:
              '<strong>Dėmesio:</strong> Jeigu pasidalinsite savo raktu su pašaliniais, jie galės naudotis jūsų Smart Gate paslaugomis. Todėl, pasidalinkite raktu tik su patikimomis šalimis.',
          },
        },
        privileges: {
          title: 'Teisės',
        },
      },
      invitations: {
        sendInvitation: 'Išsiųsti pakvietimą',
        createDialog: {
          title: 'Pakvieskite naują vartotoją',
          description:
            'Čia galite pakviesti naują vartotoją į savo Smart Gate paskyrą el. paštu. Pakvietimą galite atšaukti bet kuriuo metu.',
          addAsAdmin: 'Pakviesti kaip administratorių',
          send: 'Išsiųsti pakvietimą',
          invitationsSentSuccessfully: 'Pakvietimai sėkmingai išsiųsti',
        },
      },
      login: {
        keepMeLoggedIn: 'Prisiminti mane',
        forgotPassword: 'Pamiršote slaptažodį?',
        register: 'Naujas vartotojas? Registruokitės',
        login: 'Prisijungti',
      },
      registration: {
        title: 'Registracija',
        description: 'Sukurkite paskyrą savo<br/> <b>{{email}}</b> el. paštui.',
        createAccount: 'Sukurti paskyrą',
        alreadyHaveAccount: 'Jau turite paskyrą?',
        login: 'Prisijungti',
      },
      passwordRecovery: {
        intro: {
          title: 'Pamiršote slaptažodį?',
          description:
            'Čia galite atkurti savo slaptažodį. Įveskite savo el. paštą ir mes jums išsiųsime nuorodą, kurią galėsite paspausti ir atnaujinti savo slaptažodį.',
          sendRecoveryEmail: 'Siųsti slaptažodžio atkūrimo el. laišką',
        },
        sentInfo: {
          title: 'El. laiškas išsiųstas!',
          description:
            'El. laiškas su slaptažodžio atkūrimo nuoroda buvo išsiųstas į <b>{{email}}</b>.<br/> Prašome patikrinti savo el. paštą.',
          resendEmail: 'Iš naujo išsiųsti el. laišką',
        },
        updatePassword: {
          title: 'Slaptazodžio keitimas',
          description: 'Pakeiskite slaptažodį el. paštui <b>{{email}}</b>',
          updatePassword: 'Pakeisti slaptažodį',
        },
        updatePasswordConfirmation: {
          title: 'Slaptažodis pakeistas!',
          back: 'Grįžti į prisijungimo langą',
        },
        iRememberPassword: 'Atsimenu slaptažodį',
      },
      pageNotFound: {
        title: 'Ups, puslapis nerastas!',
        description:
          'Apgailestaujame, bet <b>puslapis</b>,<br/>kurį bandėte pasiekti, <b>nerastas</b>.',
        goTo: 'Eiti į ',
        dashboard: 'Pagrindinį puslapį',
        loginPage: 'Prisijungimo puslapį',
      },
    },
  },
};

export default lt;
