import { Trans, useTranslation } from 'react-i18next';

import { DateField, DetailedList, FunctionField } from '../../../elements';
import { useIsCurrentUserAdmin, useMediaQuery } from '../../../hooks';
import { ApiOpenTokenRecord } from '../../../interfaces/api.types';
import { Description, Title } from '../AuthorizedPages.styled';
import { DesktopListContainer, ExpiredIcon, MobileListContainer, ValidIcon, Wrapper } from './OpenTokens.styled';
import { getRowCellsStyle } from './OpenTokens.utils';

const OpenTokens = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(({ breakpoints, down }) => down(breakpoints.md));
  const isCurrentUserNonAdmin = !useIsCurrentUserAdmin();

  return (
    <Wrapper>
      <Title>{t('routes.openTokens.title')}</Title>
      {isCurrentUserNonAdmin && (
        <Description>
          <Trans i18nKey="routes.openTokens.description" />
        </Description>
      )}
      {isMobile ? (
        <MobileListContainer />
      ) : (
        <DesktopListContainer>
          <DetailedList
            resource="open-token"
            noDataLabel="routes.openTokens.noData"
            rowCellsStyle={getRowCellsStyle}
          >
            <FunctionField<ApiOpenTokenRecord>
              label="openToken.id"
              render={(ot) => `${ot.token.slice(0, 10)}...`}
            />
            <FunctionField<ApiOpenTokenRecord>
              label="openToken.guestEmail"
              render={(ot) => ot.guestEmail}
            />
            <DateField label="openToken.createDate" source="createdAt" showTime />
            <DateField label="openToken.expiryDate" source="expiryDate" showTime />
            <FunctionField<ApiOpenTokenRecord>
              label="openToken.limit"
              render={(ot) => {
                switch (ot.openActionLimit) {
                  case -1:
                    return '∞';
                  case 0:
                    return t('openToken.expired');
                  default:
                    return ot.openActionLimit;
                }
              }}
            />
            <FunctionField<ApiOpenTokenRecord>
              label="openToken.status"
              render={(ot) => (ot.valid ? <ValidIcon /> : <ExpiredIcon />)}
            />
          </DetailedList>
        </DesktopListContainer>
      )}
    </Wrapper>
  );
};

export default OpenTokens;
